import React from "react";
import Slider from "react-slick";

const Testimonials = () => {
  var settingsTestimonial = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
  };

  return (
    <div>
      <section className="testimonials">
        <div className="container" data-aos="zoom-in">
          <div
            className="testimonials-slider swiper"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <Slider {...settingsTestimonial}>
              <div className="swiper-slide">
                <div className="testimonial-item">
                  <h3>Other Testimonials</h3>

                  <h4>Student</h4>

                  <p>
                    Awesome Experience! Great mentors ​and very easy to
                    communicate with.
                  </p>
                </div>
              </div>

              <div>
                <div className="testimonial-item">
                  <h3>Ramiza Islam</h3>

                  <h4>Student</h4>

                  <p>
                    Highly motivated and proud to be ​part of techscope. Glad to
                    be one of ​their early students
                  </p>
                </div>
              </div>
              {/* <!-- End testimonial item --> */}

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <h3>Brindan Thiyagarajah</h3>

                  <h4>Student</h4>

                  <p>
                    This Team helped me transition into ​a far suited career for
                    my skills and i ​am grateful for all the connections ​and
                    foundations that TechScope has ​provided to me.
                  </p>
                </div>
              </div>
              {/* <!-- End testimonial item --> */}

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <h3>Brittany Radcliffe</h3>

                  <h4>Student</h4>

                  <p>
                    Before taking the classes here I was ​not sure that I was
                    going to be able to ​make a change into tech. Thank you
                    ​TechScope!
                  </p>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <h3>Dayana Williams</h3>

                  <h4>Student</h4>

                  <p>
                    I was struggling through the first part of the course and I
                    communicated with my teacher and mentor, they made sure to
                    provide extra material and time so that I would be able to
                    conduct my classes better and continue.
                  </p>
                </div>
              </div>
              {/* <!-- End testimonial item --> */}
            </Slider>

            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>
      {/* <!-- End Testimonials Section --> */}
    </div>
  );
};

export default Testimonials;
