import React from "react";
import "../Components/Loader.css"; // Import your loader styles
import assetsImage from "../assets/assets";
const Loader = () => {
  return (
    <div>
      <div className="loader-container">
        <div className="loader-animation">
          <div className="dot dot1"></div>
          <div className="dot dot2"></div>
          <div className="dot dot3"></div>
        </div>
        <img
          src={assetsImage.logo}
          alt="TechScope Logo"
          className="loader-logo"
        />
        <div className="loader-text">TechScope</div>
      </div>
    </div>
  );
};

export default Loader;
