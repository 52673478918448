import React from "react";
import Navbar from "../Components/Navbar";
import assetsImage from "../assets/assets";
import { Link } from "react-router-dom";
const WebAppDev = () => {
  return (
    <div>
      <Navbar />
      <div className="Development-page">
        <div className="development-bg">
          <div className="container py-5">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-5 col-xl-6 order-xl-2 order-lg-2 order-md-1 d-flex justify-content-center align-items-center">
                <img
                  className="img-fluid"
                  src={assetsImage.DevelopmentHeaderImg}
                ></img>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-7 col-xl-6 order-xl-1 order-lg-1 order-md-2 d-flex justify-content-center align-items-center">
                <div>
                  <div
                    className="section-title pr-3 py-lg-4"
                    data-aos="fade-right"
                    data-aos-delay="100"
                  >
                    <h2 className="text-white">Web and App Development</h2>

                    <p className="text-white">
                      Make your life easier with the help of
                      <span className="theme-color"> techscope</span>
                    </p>
                  </div>
                  <p className=" text-white  pe-lg-0 me-lg-0 pe-xl-5 me-xl-5">
                    Flutter transforms the app development process. Build, test,
                    and deploy beautiful mobile, web, desktop, and embedded apps
                    from a single codebase.
                  </p>
                  <div className="py-4">
                    <Link
                      to="#development-contact-form"
                      className="theme-btn btn-sm btn-yellow text-decoration-none"
                    >
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white py-5">
          <div className="container">
            <div>
              <div className="row">
                <div className="col-sm-12 col-md-11 col-lg-9 col-xl-8 mx-auto">
                  <div className="section-title pr-3 text-center">
                    <p className="">
                      <span className="theme-color">
                        {" "}
                        Application Development
                      </span>{" "}
                      Services to accelerate your{" "}
                      <span className="theme-color">business growth</span>
                    </p>
                  </div>
                  <div className="cotnent text-center">
                    <p className="text-center">
                      Our next-gen app development offering simplifies business
                      complexities for some of the most successful companies
                    </p>
                    <img
                      className="img-fluid py-3"
                      src={assetsImage.appDevServices}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="why-techscope-bg py-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-11 col-lg-9 col-xl-8 mx-auto">
                <div className="section-title pr-3 text-center">
                  <p className="">
                    Why
                    <span className="theme-color"> Techscope</span> For App
                    Development?
                  </p>
                </div>
              </div>
            </div>
            <div className="row py-3">
              <div className="col-sm-12 col-md-12 col-lg-7 col-xl-8">
                <p>
                  <img
                    height={30}
                    className=" pe-2"
                    src={assetsImage.servicesTick}
                    alt="tick"
                  ></img>{" "}
                  Optimal resource utilization
                </p>
                <p>
                  <img
                    height={30}
                    className=" pe-2"
                    src={assetsImage.servicesTick}
                    alt="tick"
                  ></img>{" "}
                  Close attention to user experience
                </p>
                <p>
                  <img
                    height={30}
                    className=" pe-2"
                    src={assetsImage.servicesTick}
                    alt="tick"
                  ></img>{" "}
                  Optimal resource utilization
                </p>
                <p>
                  <img
                    height={30}
                    className=" pe-2"
                    src={assetsImage.servicesTick}
                    alt="tick"
                  ></img>{" "}
                  Extremely collaborative and outcome-focused
                </p>
                <p>
                  <img
                    height={30}
                    className=" pe-2"
                    src={assetsImage.servicesTick}
                    alt="tick"
                  ></img>{" "}
                  Guaranteed application scaling on demand
                </p>
                <p>
                  <img
                    height={30}
                    className=" pe-2"
                    src={assetsImage.servicesTick}
                    alt="tick"
                  ></img>{" "}
                  Strict adherence to transparency and reporting
                </p>
                <p>
                  <img
                    height={30}
                    className=" pe-2"
                    src={assetsImage.servicesTick}
                    alt="tick"
                  ></img>{" "}
                  Consistent delivery by following CI/CD approaches
                </p>
                <p>
                  <img
                    height={30}
                    className=" pe-2"
                    src={assetsImage.servicesTick}
                    alt="tick"
                  ></img>{" "}
                  Proven track record in building successful MVP
                </p>
                <p>
                  <img
                    height={30}
                    className=" pe-2"
                    src={assetsImage.servicesTick}
                    alt="tick"
                  ></img>{" "}
                  Rigorous quality assurance testing before going live
                </p>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-5 col-xl-4 d-flex justify-content-center align-items-center">
                <div className="img">
                  <img
                    className="img-fluid"
                    src={assetsImage.whyAppDevImg}
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 text-cener bg-white">
          <img className="img-fluid" src={assetsImage.brandsDemo}></img>
        </div>
        <div className="py-5 bg-white">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-7 col-xl-8 order-xl-1 order-lg-1 order-md-2">
                <div
                  className="section-title pr-3"
                  data-aos="fade-right"
                  data-aos-delay="100"
                >
                  <p className="">
                    <span className="theme-color"> Mobile App</span> Development
                  </p>
                </div>
                <div className="content">
                  <p>
                    Be it native or hybrid platform, we are a mobile app
                    development company that offers a complete cycle of mobile
                    app design, integration, and development services. We
                    provide flexible engagement models to all startups to
                    enterprises and our developers build mobile apps that
                    improve user engagement.
                  </p>
                  <ul className="d-flex">
                    <li className="fw-bold" style={{ color: "#79db54" }}>
                      IOS App
                    </li>{" "}
                    <span className="px-2">Development</span>
                  </ul>
                  <p>
                    We are experts in producing fully-fledged iOS mobile apps
                    that are stable, scalable, and compatible with iPhone, iPad,
                    Apple TV, and Apple Watches.
                  </p>
                  <ul className="d-flex">
                    <li className="fw-bold" style={{ color: "#79db54" }}>
                      Android App
                    </li>{" "}
                    <span className="px-2">Development</span>
                  </ul>
                  <p>
                    Our android app developers create native apps with robust
                    and evolutionary architecture using all trending
                    technologies.
                  </p>
                  <a className="theme-btn btn-sm btn-yellow text-decoration-none">
                    Get Started
                  </a>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-5 col-xl-4  d-flex justify-content-center align-items-center">
                <img
                  className="img-fluid"
                  src={assetsImage.mobileAppDevImg}
                ></img>
              </div>
            </div>
          </div>
        </div>
        <div className="py-5 bg-white">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-7 col-xl-8">
                <div
                  className="section-title pr-3"
                  data-aos="fade-right"
                  data-aos-delay="100"
                >
                  <p className="">
                    <span className="theme-color"> Web App</span> Development
                  </p>
                </div>
                <div className="content">
                  <p>
                    We offer end-to-end web application development services
                    which include designing, prototyping, building, and
                    migrating to new web architecture. Our web design and
                    development experts are tech-savvy enthusiasts who focus on
                    creating custom web apps. We build apps that are secure,
                    scalable, and responsive.
                  </p>
                  <ul className="d-flex">
                    <li className="fw-bold" style={{ color: "#79db54" }}>
                      Web Design
                    </li>{" "}
                    <span className="px-2"> & Development</span>
                  </ul>
                  <p>
                    We focus on building web apps and websites that are visually
                    appealing with user-centric UI/UX designs that are secure,
                    database friendly, and CMS friendly.
                  </p>
                  <ul className="d-flex">
                    <li className="fw-bold" style={{ color: "#79db54" }}>
                      SaaS Product
                    </li>{" "}
                    <span className="px-2">Development</span>
                  </ul>
                  <p>
                    Our android app developers create native apps with robust
                    and evolutionary architecture using all trending
                    technologies
                  </p>
                  <ul className="d-flex">
                    <li className="fw-bold" style={{ color: "#79db54" }}>
                      Progressive Web Apps
                    </li>{" "}
                    <span className="px-2">Development</span>
                  </ul>
                  <p>
                    We bring both the web and mobile users under one PWA with
                    optimized UX, customized interface, and leveraging app shell
                    model to build native-like features.
                  </p>
                  <a className="theme-btn btn-sm btn-yellow text-decoration-none">
                    Get Started
                  </a>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-5 col-xl-4 d-flex justify-content-center align-items-center">
                <img className="img-fluid" src={assetsImage.webAppDevImg}></img>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-us-form bg-white py-5">
          <div className="container">
            <h2 className="text-white">Contact Us</h2>
            <div className="col-sm-12 col-md-12 col-lg-10 col-xl-9 mx-auto bg-white p-3 ">
              <div
                className="section-title pr-3"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <h2>Contact form</h2>

                <p className="">
                  Contact
                  <span className="theme-color"> our team</span>
                </p>
              </div>
              <form>
                <div className="mb-3">
                  <label for="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                  />
                </div>
                <div className="mb-3">
                  <label for="email" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="name@example.com"
                  />
                </div>
                <div className="mb-3">
                  <label for="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    rows="4"
                    placeholder="Your message"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="theme-btn btn-sm btn-yellow text-decoration-none"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebAppDev;
