// Background Images

import analytics from "./analytics.svg";
import bgD from "./bg-d.jpg";
import blockBg from "./block-bg.svg";
import businessAnalyst from "./business-analyst.svg";
import circleBlue from "./circle-blue.png";
import countsImg from "./counts-img.jpg";
import ctaBg from "./cta-bg.jpg";
import dataEng from "./data-eng.svg";
import dataEngBg from "./data-eng-bg.svg";
import dataSciBg from "./data-sci-bg.svg";
import heroBg from "./hero-bg.jpg";
import testimonials from "./testimonials-bg.jpg";
import training from "./training.png";
import intro from "./images/intro-img.png";
import trainingBig from "./training.svg";
import courses from "./courses.svg";
import steps from "./images/steps.png";
import steps4 from "./4-steps.png";
import teamStaffing from "./images/team-staffing.svg";
import staffing from "./images/staffing.svg";
import consult from "./images/consult.svg";
import contruction from "./images/under-construction.jpg";
import contactImg from "./images/contact.png";
import trainingSpecial from "./special1.jpg";
import staffingSpecial from "./special2.jpg";
import consultingSpecial from "./special3.jpg";
import commaImg from "./comma.png";
import percent28 from "./28.svg";
import percent50 from "./50.svg";
import percent95 from "./95.svg";

import logo from "./images/logo.png";
import staffingBg from "./images/bg-staffing.png";
import retail from "./images/retail.svg";
import banking from "./images/banking.svg";
import health from "./images/healtcare.svg";
import auto from "./images/auto.svg";

import rohan from "./images/rohan.jpg";
import max from "./images/max.png";
import awais from "./images/awais.jpg";
import jubaer from "./images/jubaer.jpg";
import farman from "./images/farman.jpg";
import junaid from "./images/junaid.jpg";
import afran from "./images/afran.jpg";
import community from "./images/cummunity.svg";

import event1 from "./images/event1.jpg";
import event2 from "./images/event2.jpg";
import event3 from "./images/event3.jpg";
import event4 from "./images/event4.jpg";
import event5 from "./images/event5.jpg";

import facebookIco from "./images/facebook.svg";
import twitterIco from "./images/twitter.svg";
import youtubeIco from "./images/youtube.webp";
import windowsIco from "./images/windows.webp";
import healthIco from "./images/health-com.svg";
import flagIco from "./images/flag.svg";
import naveed from "./Navid.webp";
import shivam from "./shivam.webp";
import manStanding from "./images/man-standing.svg";
import done from "./done.svg";
import introVid from "./Videos/intro.mp4";
import homepageVideo from "./Videos/intro-video.mp4";
import CorporateVid from "./Videos/corporate.mp4";
import Corporatebg from "./Videos/video-bg.svg";
import heroesHeading from "./heros.png";
import teamHeroes from "./team.png";
import consultingAbout from "./about.jpg";
import directors from "./directors.png";
import experienceImg from "./experience.png";
import ceo from "./ceo.webp";
import news from "./news.webp";
import yahoo from "./yahoo.webp";
import customerSupport from "./customer-support.png";
import lifeInsurance from "./life-insurance.png";
import BusinessInsider from "./business-insider.png";
import APNews from "./AP-news.svg";
import company1 from "./about/our-company-1.png";
import company2 from "./about/our-company-2.png";
import line from "./about/line.svg";
import talentSolution from "./talent-solution.png";
import training1 from "./images/training1.jpg";
import training2 from "./images/training2.jpg";

import blogImage1 from "./Blogs/blog1.webp";
import blogImage2 from "./Blogs/blog2.webp";
import blogImage3 from "./Blogs/blog3.webp";
import blogImage4 from "./Blogs/blog4.webp";
import blogImage5 from "./Blogs/blog5.webp";
import blogImage6 from "./Blogs/blog6.webp";
import blogDetail from "./Blogs/blog-detail-img.webp";

import trainingHeader from "./Training-page/Header image .webp";
import speciality from "./Training-page/Specialiaty.webp";
import dataEngineering from "./Training-page/data-engineering.webp";
import dataAnalytics from "./Training-page/data-analytics.webp";
import dataScience from "./Training-page/data-science.webp";
import saleForce from "./Training-page/sales-force.webp";
import blockchainTech from "./Training-page/blockchain.webp";
import shiwamIntructor from "./Training-page/shivam.webp";
import navidInstructor from "./Training-page/Navid.webp";
import mentorImg from "./Training-page/mentor.webp";
import starterPkg from "./Training-page/Starter-pkg.webp";
import standardPkg from "./Training-page/Standard-pkg.webp";
import enterprisePkg from "./Training-page/Enterprise-pkg.webp";
import videoSample from "./Training-page/video-sample.webp";
import rohanCEO from "./images/rohan.jpeg";
import qouteStart from "./images/quotestart.png";
import qouteEnd from "./images/quote.png.png";
import techWeProcess from "./tech-we-progress.png";

import DevelopmentHeaderImg from "./web and app dev/home-reputable-success.webp";
import developmentHeaderBg from "./web and app dev/Why-Techscope-bg.webp";
import appDevServices from "./web and app dev/application-dev-banner.webp";
import whyAppDevImg from "./web and app dev/Why-App.webp";
import brandsDemo from "./web and app dev/brands-demo.webp";
import mobileAppDevImg from "./web and app dev/mobile-dev-img.webp";
import webAppDevImg from "./web and app dev/web-dev-img.webp";
import servicesTick from "./web and app dev/Check All.webp";
const assetsImage = {
  servicesTick,
  webAppDevImg,
  mobileAppDevImg,
  brandsDemo,
  whyAppDevImg,
  appDevServices,
  developmentHeaderBg,
  DevelopmentHeaderImg,
  techWeProcess,
  qouteStart,
  qouteEnd,
  rohanCEO,
  videoSample,
  mentorImg,
  starterPkg,
  standardPkg,
  enterprisePkg,
  navidInstructor,
  shiwamIntructor,
  dataEngineering,
  dataAnalytics,
  dataScience,
  saleForce,
  blockchainTech,
  speciality,
  trainingHeader,
  training1,
  training2,
  talentSolution,
  homepageVideo,
  line,
  company2,
  company1,
  APNews,
  BusinessInsider,
  customerSupport,
  lifeInsurance,
  ceo,
  news,
  yahoo,
  experienceImg,
  heroesHeading,
  directors,
  teamHeroes,
  percent28,
  percent50,
  percent95,
  CorporateVid,
  Corporatebg,
  consultingAbout,
  analytics,
  bgD,
  blockBg,
  businessAnalyst,
  circleBlue,
  countsImg,
  ctaBg,
  dataEng,
  dataEngBg,
  dataSciBg,
  heroBg,
  testimonials,
  training,
  logo,
  intro,
  staffingBg,
  auto,
  banking,
  health,
  retail,
  rohan,
  max,
  awais,
  jubaer,
  farman,
  junaid,
  afran,
  community,
  event1,
  event2,
  event3,
  event4,
  event5,
  facebookIco,
  twitterIco,
  youtubeIco,
  windowsIco,
  healthIco,
  flagIco,
  trainingBig,
  courses,
  naveed,
  shivam,
  steps,
  teamStaffing,
  staffing,
  steps4,
  consult,
  manStanding,
  done,
  contruction,
  contactImg,
  introVid,
  trainingSpecial,
  staffingSpecial,
  consultingSpecial,
  commaImg,
  blogImage1,
  blogImage2,
  blogImage3,
  blogImage4,
  blogImage5,
  blogImage6,
  blogDetail,
};

export default assetsImage;
