import React, { useEffect, useState } from "react";
import ParticleAnimation from "./TsParticles";
import assetsImage from "../assets/assets";
import { FaPhoneSquareAlt, FaClock, FaEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";
// importing aos
const HomeHeader = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const closeOffcanvas = () => setShowOffcanvas(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    setTimeout(function () {}, 100);
  }, []);
  return (
    <div>
      <ParticleAnimation />
      <div>
        {/* <!-- ======= Header ======= --> */}

        <header
          className={`${scrolled ? "scrolled" : ""} fixed-top shadow`}
          id="header"
        >
          <div>
            <div className="container-fluid d-none ">
              <div className="row upper-navbar ">
                <div className="col-md-3">
                  <Link className="navbar-brand" to="/">
                    <img height="80px" alt="" src={assetsImage.logo} />
                  </Link>
                </div>
                <div className="col-md-9">
                  <div className="d-flex contact-info justify-content-end">
                    <div className="d-flex border border-top-0 border-bottom-0 border-start-0 px-3 pt-3">
                      <div className="icon text-white pe-2">
                        <FaPhoneSquareAlt />
                      </div>
                      <a
                        href="tel:+1 (929) 235-5888"
                        className="text-white text-decoration-none"
                      >
                        +1 (929) 235-5888
                      </a>
                    </div>
                    <div className="d-flex border border-top-0 border-bottom-0 border-start-0 px-3 pt-3">
                      <div className="icon text-white pe-2">
                        <FaEnvelope />
                      </div>
                      <a
                        href="mailto:consulting@techscope.org"
                        className="text-white text-decoration-none"
                      >
                        consulting@techscope.org
                      </a>
                    </div>
                    <div className="d-flex px-3 pt-3">
                      <div className="icon text-white pe-2">
                        <FaClock />
                      </div>
                      <p
                        href="tel:+1 (929) 235-5888"
                        className="text-white text-decoration-none"
                      >
                        Mon - Sat : 08am - 12pm
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <nav className="navbar navbar-expand-lg navbar-dark ms-auto py-1 row">
                <div className="col-md-2 d-none d-sm-none d-md-none d-lg-block">
                  <Link className="navbar-brand" to="/">
                    <img height="80px" alt="" src={assetsImage.logo} />
                  </Link>
                </div>
                <div className="col-2 col-sm-1 col-md-2 d-block d-sm-block d-md-block d-lg-none">
                  <button
                    className="btn btn-outline-secondary"
                    onClick={() => setShowOffcanvas(true)}
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                </div>

                <div className="col col-sm-3 col-md-2 col-lg-2 text-end d-block d-sm-block d-md-block d-lg-none">
                  <Link className="navbar-brand" to="/">
                    <img alt="" className="img-fluid" src={assetsImage.logo} />
                  </Link>
                </div>

                <div className="col-md-9 col-lg-8 col-xl-9 d-none d-sm-none d-md-none d-lg-block">
                  <div
                    className="collapse navbar-collapse justify-content-center"
                    id="navbarNav"
                  >
                    <ul className="navbar-nav align-items-center">
                      <li className="nav-item main-nav-link px-2">
                        <Link
                          className="nav-link active scrollto"
                          aria-current="page"
                          to="/"
                        >
                          Home
                        </Link>
                      </li>
                      <li className="nav-item main-nav-link px-2">
                        <Link className="nav-link scrollto" to="/about">
                          About
                        </Link>
                      </li>
                      <li className="nav-item main-nav-link px-2">
                        <Link className="nav-link " to="/training">
                          Training
                        </Link>
                      </li>
                      <li className="nav-item main-nav-link px-2">
                        <Link
                          className="nav-link "
                          to="/corporate/talent-solutions"
                        >
                          Talent Solutions
                        </Link>
                      </li>
                      <li className="nav-item main-nav-link px-2">
                        <Link className="nav-link " to="/corporate/consulting">
                          Consulting
                        </Link>
                      </li>
                      <li className="nav-item main-nav-link px-2">
                        <Link className="nav-link " to="/Web&App-Development">
                          Development
                        </Link>
                      </li>
                      {/* <li className="nav-item main-nav-link px-2">
                        <Link className="nav-link " to="/Blogs">
                          Blogs
                        </Link>
                      </li> */}
                      <li className="nav-item main-nav-link px-2">
                        <Link to="/contact" className="nav-link scrollto">
                          <span className="">Contact Us</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-4 col-sm-3 col-md-2 col-lg col-xl text-center">
                  <a
                    className="theme-btn btn-yellow scrollto text-decoration-none d-block d-sm-block d-md-none"
                    href="tel:+1 (929) 235-5888"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Call Now
                  </a>
                  <a
                    className="theme-btn scrollto text-decoration-none d-none d-sm-none d-md-block btn-yellow"
                    href="https://calendly.com/rohanhossain47/screening-call-with-techsscope?month=2024-04"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Book A Call
                  </a>
                </div>
                <div className="col-12 d-block d-sm-block d-md-block d-lg-none">
                  <div
                    className={`offcanvas offcanvas-start text-bg-dark bg-dark ${
                      showOffcanvas ? "show" : ""
                    }`}
                    id="side-navbar"
                  >
                    <div className="offcanvas-header py-2">
                      <Link
                        className="navbar-brand"
                        to="/"
                        onClick={closeOffcanvas}
                      >
                        <img
                          height="90px"
                          className="img-fluid"
                          src={assetsImage.logo}
                        />
                      </Link>
                      <button
                        type="button"
                        className="btn-close text-white btn-close-white"
                        onClick={closeOffcanvas}
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <ul className="navbar-nav ms-auto">
                        <li className="nav-item main-nav-link border border-top-0 border-start-0 border-end-0">
                          <Link
                            className="nav-link active"
                            aria-current="page"
                            to="/"
                            onClick={closeOffcanvas}
                          >
                            Home
                          </Link>
                        </li>
                        <li className="nav-item main-nav-link border border-top-0 border-start-0 border-end-0">
                          <Link
                            className="nav-link"
                            onClick={closeOffcanvas}
                            to="/about"
                          >
                            About
                          </Link>
                        </li>
                        <li className="nav-item main-nav-link border border-top-0 border-start-0 border-end-0">
                          <Link
                            onClick={closeOffcanvas}
                            to="/training"
                            className="nav-link scrollto"
                          >
                            Training
                          </Link>
                        </li>
                        <li className="nav-item main-nav-link border border-top-0 border-start-0 border-end-0">
                          <Link
                            className="nav-link "
                            to="/corporate/talent-solutions"
                          >
                            Talent Solutions
                          </Link>
                        </li>
                        <li className="nav-item main-nav-link border border-top-0 border-start-0 border-end-0">
                          <Link
                            className="nav-link "
                            to="/corporate/consulting"
                          >
                            Consulting
                          </Link>
                        </li>
                        <li className="nav-item main-nav-link border border-top-0 border-start-0 border-end-0">
                          <Link className="nav-link " to="/Web&App-Development">
                            Web and App Development
                          </Link>
                        </li>
                        <li className="nav-item main-nav-link border border-top-0 border-start-0 border-end-0">
                          <Link to="/contact" className="nav-link scrollto">
                            <span className="">Contact Us</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <div className="container d-none d-flex align-items-center justify-content-lg-between">
            <Link to="/" className="logo me-auto me-lg-0">
              <img alt="logo" src={assetsImage.logo} />
            </Link>

            <nav className="navbar order-last order-lg-0" id="navbar">
              <ul>
                <li>
                  <Link to="/" className="nav-link scrollto">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="nav-link scrollto">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/training" className="nav-link scrollto">
                    Training
                  </Link>
                </li>
                <li>
                  <Link
                    to="/corporate/talent-solutions"
                    className="nav-link scrollto"
                  >
                    Talent Solutions
                  </Link>
                </li>
                <li>
                  <Link
                    to="/corporate/consulting"
                    className="nav-link scrollto"
                  >
                    Consulting
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="nav-link scrollto">
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
            {/* <!-- .navbar --> */}
          </div>
        </header>
        {/* <!-- End Header --> */}
        {/* <!-- ======= header-box Section ======= --> */}

        <section
          className="d-flex align-items-center justify-content-center"
          id="header-box"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-8">
                <h1>
                  YOUR <span className="theme-color">INNOVATIVE </span> FUTURE
                  AWAITS<span className="theme-color">.</span>
                </h1>
              </div>
            </div>

            <div className="row gy-4 mt-5 justify-content-center">
              <div className="col-xl-3 col-md-4">
                <Link to="./training" className="text-decoration-none">
                  <div className="icon-box">
                    <img
                      loading="lazy"
                      decoding="async"
                      className="img-fluid"
                      height="100"
                      src={assetsImage.training}
                      width="100"
                      alt=""
                    />
                    <h3 className="text-white">Training</h3>
                  </div>
                </Link>
              </div>

              <div className="col-xl-3 col-md-4">
                <Link
                  to="/corporate/talent-solutions"
                  className="text-decoration-none"
                >
                  <div className="icon-box">
                    <img
                      loading="lazy"
                      decoding="async"
                      className="img-fluid"
                      height="100"
                      src={assetsImage.businessAnalyst}
                      width="100"
                      alt=""
                    />
                    <h3 className="text-white">Talent Solutions</h3>
                  </div>
                </Link>
              </div>
              <div className="col-xl-3 col-md-4">
                <Link
                  to="/corporate/consulting"
                  className="text-decoration-none"
                >
                  <div className="icon-box">
                    <img
                      loading="lazy"
                      decoding="async"
                      className="img-fluid"
                      height="100"
                      src={assetsImage.dataEngBg}
                      width="100"
                      alt=""
                    />
                    <h3 className="text-white">Consulting</h3>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End header-box --> */}
      </div>
    </div>
  );
};

export default HomeHeader;
