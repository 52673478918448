import React from "react";
import { Link } from "react-router-dom";
import assetsImage from "../assets/assets";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
  FaClock,
} from "react-icons/fa";
const Footer = () => {
  return (
    <div>
      {/* <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="footer-info">
                  <Link
                    to="./"
                    className="text-decoration-none logo me-auto me-lg-0"
                  >
                    <img className="img-fluid" alt="" src={assetsImage.logo} />
                  </Link>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>

                <ul>
                  <li>
                    <Link className="text-decoration-none" to="/about">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="./training" className="text-decoration-none">
                      Training
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/corporate/talent-solutions"
                      className="text-decoration-none"
                    >
                      Talent Solutions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/corporate/consulting"
                      className="text-decoration-none"
                    >
                      Consulting
                    </Link>
                  </li>
                  <li>
                    <Link to="./contact" className="text-decoration-none">
                      Contact us
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>

                <ul>
                  <li>
                    <Link to="/corporate" className="text-decoration-none">
                      Corporate
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="text-decoration-none">
                      Data Engineering
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="text-decoration-none">
                      Data Science
                    </Link>
                  </li>
                  <li>
                    <Link to="" className="text-decoration-none">
                      Data analytics
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="text-decoration-none">
                      Cloud Computing
                    </Link>
                  </li>
                  <li>
                    <Link to="" className="text-decoration-none">
                      Salesforce
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-6 footer-newsletter">
                <h4>Contact us</h4>

                <div className="social-links mt-3"></div>
                <div className="social-links mt-3">
                  <a
                    href="https://www.facebook.com/profile.php?id=100087709806681&mibextid=LQQJ4d"
                    className="facebook"
                  >
                    <FaFacebookF />
                  </a>
                  <a
                    href="https://www.instagram.com/techscope_staffing/"
                    className="instagram"
                  >
                    <FaInstagram />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/techscope-edu"
                    className="linkedin"
                  >
                    <FaLinkedinIn />
                  </a>
                </div>
                <div className="py-3">
                  <Link
                    className="get-started-btn scrollto text-decoration-none"
                    to="/contact"
                  >
                    Contact us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>Techscope</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer> */}
      <footer
        class="footer-1 footer-2 overflow-hidden"
        // style="background-image: url(assets/img/footer/footer-bg-2.png);"
      >
        <div class="footer-top__cta mb-md-4">
          <div class="container-xl">
            <div class="row">
              <div class="col-12">
                <div class="footer-top__cta-content-wrapper pb-md-5">
                  <div
                    class="footer-top__cta-content text-center mx-auto wow"
                    data-wow-delay=".3s"
                  >
                    <h2 class="title text-white mb-sm-2 mb-lg-4 mb-sm-2">
                      Feel Free To Contact Us
                    </h2>
                    <div class="description text-white mb-lg-5 mb-md-4 mb-sm-1 fw-500 mx-auto">
                      <p>
                        Have questions or need assistance? We're here to help!
                        Reach out to us through our contact form and let's
                        discuss how we can collaborate.
                      </p>
                    </div>

                    <Link to="/contact" class="theme-btn btn-sm btn-yellow">
                      Contact Us <i class="fab fa-telegram-plane"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-xl">
          <div class="row justify-content-between py-md-5 py-3">
            <div class="col-md-6 col-lg-3 col-xl-4">
              <Link
                to="./"
                className="text-decoration-none logo me-auto me-lg-0"
              >
                <img className="img-fluid" alt="" src={assetsImage.logo} />
              </Link>
            </div>

            <div class="col-md-6 col-lg-2 col-xl-2">
              <div class="single-footer-wid pl-xl-10 pl-50">
                <h4 class="wid-title mb-3 fw-bold text-white">Quick Link</h4>

                <ul className="px-0 pt-2">
                  <li>
                    <Link className="text-decoration-none" to="/about">
                      <i className="pe-2"></i>
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="./training" className="text-decoration-none">
                      <i className="pe-2"></i>
                      Training
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/corporate/talent-solutions"
                      className="text-decoration-none"
                    >
                      <i className="pe-2"></i>
                      Talent Solutions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/corporate/consulting"
                      className="text-decoration-none"
                    >
                      <i className="pe-2"></i>
                      Consulting
                    </Link>
                  </li>
                  <li>
                    <Link to="./contact" className="text-decoration-none">
                      <i className="pe-2"></i>
                      Contact us
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://techscope.org/admin/login.php"
                      className="text-decoration-none"
                      target="_blank"
                    >
                      <i className="pe-2"></i>
                      Login
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-6 col-lg-3 col-xl-2">
              <div class="single-footer-wid pl-xl-10 pl-50">
                <h4 class="wid-title mb-3 fw-bold text-white">Our Services</h4>

                <ul className="px-0 pt-2">
                  <li>
                    <Link className="text-decoration-none" to="/corporate">
                      <i className="pe-2"></i>
                      Corporate
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="text-decoration-none">
                      <i className="pe-2"></i>
                      Data Engineering
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="text-decoration-none">
                      <i className="pe-2"></i>
                      Data Science
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="text-decoration-none">
                      <i className="pe-2"></i>
                      Data analytics
                    </Link>
                  </li>
                  <li>
                    <Link to="./" className="text-decoration-none">
                      <i className="pe-2"></i>
                      Cloud Computing
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-6 col-lg-4 col-xl-3">
              <div class=" contact_widget">
                <h4 class="wid-title mb-3 fw-bold text-white">Working Time</h4>

                <div class="contact-wrapper pt-30 pr-30 pb-30 pl-30 bg-dark">
                  <div class="wid-contact py-4">
                    <ul>
                      <li className="d-flex">
                        <div class="icon text-white pe-3">
                          <FaClock />
                        </div>

                        <div class="contact-info font-la text-white">
                          <p>Mon - Sat / 08am : 12pm</p>
                        </div>
                      </li>

                      <li className="d-flex">
                        <div class="icon text-white pe-3">
                          <FaClock />
                        </div>

                        <div class="contact-info font-la text-white">
                          <p>Sunday Close</p>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="social-profile">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/profile.php?id=100087709806681&mibextid=LQQJ4d"
                          className="facebook"
                          target="_blank"
                        >
                          <i>
                            <FaFacebookF />
                          </i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/techscope_staffing/"
                          className="instagram"
                          target="_blank"
                        >
                          <i>
                            {" "}
                            <FaInstagram />
                          </i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.linkedin.com/company/techscope-edu"
                          className="linkedin"
                          target="_blank"
                        >
                          <i>
                            <FaLinkedinIn />
                          </i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="single-footer-wid">
                {/* <h4 class="wid-title mb-30 text-white">Office Location</h4>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3024.1737541187717!2d-74.0091097242891!3d40.71419133748196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2434b7b4bcd19%3A0x4e5461e4aacd2cf5!2sMunicipal%20Credit%20Union!5e0!3m2!1sen!2s!4v1709084439741!5m2!1sen!2s"
                  allowfullscreen=""
                  loading="lazy"
                  height="100%"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe> */}
              </div>
            </div>
          </div>
        </div>

        <div class="footer-bottom overflow-hidden">
          <div class="container">
            <div class="footer-bottom-content text-center">
              <div class="coppyright text-center">
                © 2023 <Link to="/">Techscope</Link> | All Rights Reserved
                {/* by{" "}
                <a href="https://www.linkedin.com/in/muzamil-niaz-68b9991a3/">
                  Webmatics
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
