import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import assetsImage from "../assets/assets";
import { VideoHTMLAttributes } from "react";
const Corporate = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <Navbar />
      <div className="container">
        <section className="started py-5">
          <div className="row">
            <div className="col-sm-12 col-md-6 py-5">
              <div className="section-title pt-lg-5">
                <h2>How we started?</h2>
                <p className="text-white">
                  Our <span className="theme-color">We Started</span> Small
                </p>
              </div>
              <p className="text-white">
                To be completely honest, TechScope is still considered a small
                and nimble company compared to some of the larger competition
                but that is what makes us different. We believe that is our
                strength, we care about our clients and we value our
                relationships more than anything. Buidling a successful
                relationship dwells on rapport and with a leap of faith we
                believe we would be able to prove ourselves.
              </p>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="corporate-vid text-center">
                <video
                  src={assetsImage.CorporateVid}
                  width="450px"
                  height="450px"
                  autoplay="true"
                  loop="true"
                  className="rounded-circle"
                ></video>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="whats-special">
        <div className="container">
          <div className="section-title pt-lg-5">
            <h2>Our Speciality</h2>
            <p className="text-white">
              What's{" "}
              <span className="theme-color">
                {" "}
                Special <br></br> about
              </span>{" "}
              techscope?
            </p>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <img
                className="img-fluid rounded-circle"
                src={assetsImage.trainingSpecial}
              ></img>
              <div className="section-title pt-lg-5">
                <p className="text-white fs-3">
                  <span className="theme-color">Training</span>
                </p>
                <div className="text-white">
                  Corporate Training in Data Solution, Cloud Computing,
                  Salesforce, Blockchain and Cybersecurity
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <img
                className="img-fluid rounded-circle"
                src={assetsImage.staffingSpecial}
              ></img>
              <div className="section-title pt-lg-5">
                <p className="text-white fs-3">
                  <span className="theme-color">Staffing</span>
                </p>
                <div className="text-white">
                  Bridging the gaps between clients and candidates in Tech,
                  Construction and HealthCare industries.
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <img
                className="img-fluid rounded-circle"
                src={assetsImage.consultingSpecial}
              ></img>
              <div className="section-title pt-lg-5">
                <p className="text-white fs-3">
                  <span className="theme-color">Consulting</span>
                </p>
                <div className="text-white">
                  Corporate Development in Data Solution, Cloud Computing,
                  Salesforce, Blockchain and Cybersecurity
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="choose-us">
        <div className="container">
          <div className="section-title pt-lg-5">
            <h2>choose us</h2>
            <p className="text-white">
              Why <span className="theme-color"> Choose </span>
              us?
            </p>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <div className="choose-us-section text-end">
                <h5 className="theme-color fw-bold">CUSTOMIZED FEATURES</h5>
                <p className="text-white">
                  Integration of client requests to attribute a success
                  partenership.
                </p>
                <img className="img-fluid" src={assetsImage.commaImg}></img>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="choose-us-section text-end pt-lg-5">
                <h5 className="theme-color fw-bold">EASE OF USE</h5>
                <p className="text-white">
                  Self documenting code and clear presentations.
                </p>
                <img className="img-fluid" src={assetsImage.commaImg}></img>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="choose-us-section text-end pt-lg-5 mt-lg-5">
                <h5 className="theme-color fw-bold">24/7 SUPPORT</h5>
                <p className="text-white">
                  Maintained by on-shore and off-shore teams.
                </p>
                <img className="img-fluid" src={assetsImage.commaImg}></img>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="clients">
        <div className="container">
          <div className="section-title text-center pt-lg-5">
            <h2>why us</h2>
            <p className="text-white">
              Our <span className="theme-color"> Clients </span>
              Agree
            </p>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-4 text-center px-5">
              <img className="img-fluid" src={assetsImage.percent95}></img>
              <div className="pt-3">
                <span className="percentage-box bg-white text-dark fs-2 fw-bold px-3">
                  95%
                </span>
                <p className="text-white">satisfaction rating</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 text-center px-5">
              <img className="img-fluid" src={assetsImage.percent50}></img>
              <div className="pt-3">
                <span className="percentage-box bg-white text-dark fs-2 fw-bold px-3">
                  50%
                </span>
                <p className="text-white">increase in users</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 text-center px-5">
              <img
                className="img-fluid"
                height={250}
                src={assetsImage.percent28}
              ></img>
              <div className="pt-3">
                <span className="percentage-box bg-white text-dark fs-2 fw-bold px-3">
                  28
                </span>
                <p className="text-white">active requirements</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="heroes-team">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 px-0">
              <img
                src={assetsImage.teamHeroes}
                className="img-fluid w-100 rounded-start"
              ></img>
            </div>
            <div className="col-sm-12 col-md-6 px-0">
              <img
                src={assetsImage.heroesHeading}
                className="img-fluid w-100 rounded-end"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="cta" id="cta">
        <div className="container" data-aos="zoom-in">
          <div className="text-center">
            <h3>
              Virtual <span className="theme-color">Coffee?</span>
            </h3>

            <p className="px-lg-5 mx-lg-5 py-3 fs-2">
              A company made specifically for engineers, by engineers.
            </p>
            <a
              className="cta-btn text-decoration-none"
              href="https://calendly.com/techscopestaffing/15-minute-chat-with-rohan"
              target="_blank"
              rel="noreferrer"
            >
              Book a call
            </a>
          </div>
        </div>
      </section>

      {/* <div className="container text-center">
        <img className="img-fluid" src={assetsImage.contruction} alt="" />
      </div> */}
    </div>
  );
};

export default Corporate;
