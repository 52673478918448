import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Testimonials from "../Components/Testimonials";
import assetsImage from "../assets/assets";

const Consulting = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <Navbar />
      {/* <!-- Intro --> */}
      <div className="container text-white">
        <div className="row">
          <div
            className="col-sm-12 col-md-6 py-5 pe-5"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <div className="section-title pt-lg-5">
              <h2>Consulting</h2>
              <p className="text-white">
                About <span className="theme-color">Us</span>
              </p>
            </div>
            <p>
              TechScope is a team of field-active experts offering data-centric
              and Salesforce consulting services. With a strong commitment to
              resolving real-world challenges, we utilize our partnerships with
              Databricks, AWS, GCP, and Salesforce to deliver innovative,
              tailored solutions. We empower businesses to unlock their
              potential through data-driven insights and strategic technology
              implementations.
            </p>
          </div>
          <div
            className="col-sm-12 col-md-6"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <img
              alt=""
              src={assetsImage.consultingAbout}
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      {/* <!-- End Intro --> */}
      {/* Directors section */}
      <section className="directors">
        <div className="container">
          <div className="row">
            <div
              className="col-sm-12 col-md-5"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <img alt="" src={assetsImage.directors} className="img-fluid" />
            </div>
            <div
              className="col-sm-12 col-md-7 py-5 pe-5"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <div className="section-title pt-lg-5">
                <h2>data engineering professionals</h2>
                <p className="text-white">
                  Our <span className="theme-color">Directors</span>
                </p>
              </div>
              <p className="text-white pe-lg-5">
                Our team at TechScope comprises of dedicated partners, bolstered
                by seasoned data engineering professionals, all united in their
                pursuit of tech and business excellence. With deep expertise in
                training, staffing, and consulting across tech and construction
                domains, we harness our collective knowledge to deliver optimal
                results for our clients.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Directors section end */}
      {/* Experience section */}
      {/* <section className="experience">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-9">
              <img
                className="img-fluid w-100"
                src={assetsImage.experienceImg}
              ></img>
            </div>
            <div className="col-sm-12 col-md-3">
              <p className="text-white pt-md-5">
                Our hands-on experience, backed by strategic partnerships with
                leading tech providers like Databricks, AWS, GCP, and
                Salesforce, allows us to craft tailored solutions for diverse
                industry needs.
              </p>
            </div>
          </div>
        </div>
      </section> */}
      {/* Start of process */}
      <section className="process">
        <div className="container">
          <div className="timeline-main">
            <div className="timeline-wrap">
              <div className="timeline-card">
                <div className="timeline-card-wrap">
                  <div className="card-head-wrap">
                    <h2 className="timeline-card-head">
                      Understanding Client Requirements:
                    </h2>
                    <h6 className="timeline-card-subhead">#1</h6>
                  </div>
                  <p className="timeline-card-text">
                    Personalized calls with our experts listening in to make
                    sure we all collaborate and figure out the best solution
                    approach.
                  </p>
                </div>
              </div>
              <div className="timeline-card">
                <div className="timeline-card-wrap">
                  <div className="card-head-wrap">
                    <h2 className="timeline-card-head">
                      Sourcing and Gathering Resources:
                    </h2>
                    <h6 className="timeline-card-subhead">#2</h6>
                  </div>
                  <p className="timeline-card-text">
                    We will get a solid plan in place and present that to the
                    client to help us both understand what needs to be done and
                    how it needs to be implemented.
                  </p>
                </div>
              </div>
              <div className="timeline-card">
                <div className="timeline-card-wrap">
                  <div className="card-head-wrap">
                    <h2 className="timeline-card-head">
                      Weekly Checking Calls:
                    </h2>
                    <h6 className="timeline-card-subhead">#3</h6>
                  </div>
                  <p className="timeline-card-text">
                    We will have our teams update and stay in sync with
                    Organization Standup call and managers will have weekly
                    discussions with client leadership.
                  </p>
                </div>
              </div>
              <div className="timeline-card">
                <div className="timeline-card-wrap">
                  <div className="card-head-wrap">
                    <h2 className="timeline-card-head">Guarantee:</h2>
                    <h6 className="timeline-card-subhead">#4</h6>
                  </div>
                  <p className="timeline-card-text">
                    We will make sure we stick around long term and gladly
                    assist our clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of process */}
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Testimonials</h2>
          <p className="text-white">
            Words By <span className="theme-color">Our Students</span>
          </p>
        </div>
      </div>
      <Testimonials />
    </div>
  );
};

export default Consulting;
