import React from "react";
import assetsImage from "../assets/assets";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";

const Thanks = () => {
  return (
    <>
      <Navbar />
      <div className="container">
        <div className="text-center py-5">
          <img
            style={{ height: "200px" }}
            src={assetsImage.done}
            alt=""
            className="img-fluid"
          />
          <div className="py-3">
            <h3 className="text-white">
              Your form has been submitted{" "}
              <span className="theme-color">Successfully!</span>
            </h3>
            <h4 className="text-white">Go back to: </h4>
            <Link to="/" className="get-started-btn text-decoration-none">
              Home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thanks;
