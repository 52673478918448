import React from "react";
import assetsImage from "../assets/assets";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
const Blogs = () => {
  const blogData = [
    {
      id: 1,
      image: assetsImage.blogImage1,
      name: "TechScope Training Achieves",
      date: "November 21, 2023",
      description:
        "Rohan Hossain, CEO of TechScope, has been instrumental in steering the company's educational programs to align with the evolving demands of the tech sector, facilitating career progression for students. His strategic leadership and dedication to aligning the institution's educational programs with the ever-evolving demands of the tech sector have propelled TechScope to new heights.",
    },
    {
      id: 2,
      image: assetsImage.blogImage2,
      name: "Blog Name 2",
      date: "March 14, 2024",
      description:
        "Lorem ipsum dolor sit amet, consectetur adip iscing elit. Molestie blandit nibh.",
    },
    {
      id: 3,
      image: assetsImage.blogImage3,
      name: "Blog Name 2",
      date: "March 14, 2024",
      description:
        "Lorem ipsum dolor sit amet, consectetur adip iscing elit. Molestie blandit nibh.",
    },
    {
      id: 4,
      image: assetsImage.blogImage4,
      name: "Blog Name 2",
      date: "March 14, 2024",
      description:
        "Lorem ipsum dolor sit amet, consectetur adip iscing elit. Molestie blandit nibh.",
    },
  ];
  const firstBlog = blogData[0];
  const secondBlog = blogData[1];
  return (
    <div>
      <Navbar />

      <div className="bg-white">
        <section className="recent-blogs py-4">
          <div className="container">
            <h3 className="py-2">Recent blog posts</h3>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-5 col-xl-7 first-blog pb-4">
                <img
                  className="img-fluid shadow rounded w-100"
                  src={firstBlog.image}
                  alt={firstBlog.name}
                />
                <p className="fw-bold color-primary py-2 mb-0">
                  {firstBlog.date}
                </p>
                <h2>{firstBlog.name}</h2>
                <p className="desc">{firstBlog.description}</p>
                <Link to="/Blogs/blog-name" className="read-more">
                  Read More
                </Link>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-7 col-xl-5">
                <div className="row pb-4">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                    <img
                      className="img-fluid shadow rounded w-100"
                      src={secondBlog.image}
                      alt={secondBlog.name}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                    <div className="second-blog">
                      <p className="fw-bold color-primary py-2 mb-0">
                        {secondBlog.date}
                      </p>
                      <h4>{secondBlog.name}</h4>
                      <p className="desc">{secondBlog.description}</p>
                      <Link to="/Blogs/blog-name" className="read-more">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row pb-2">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                    <img
                      className="img-fluid shadow rounded w-100"
                      src={secondBlog.image}
                      alt={secondBlog.name}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                    <div className="second-blog">
                      <p className="fw-bold color-primary py-2 mb-0">
                        {secondBlog.date}
                      </p>
                      <h4>{secondBlog.name}</h4>
                      <p className="desc">{secondBlog.description}</p>
                      <Link to="/Blogs/blog-name" className="read-more">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb-lg-5">
          <div className="container">
            <h4 className="py-2">All blog posts</h4>
            <div className="row">
              {blogData.map((blog) => (
                <div
                  className="col-12 col-sm-6 col-md-4 blog-post py-3"
                  key={blog.id}
                >
                  <img
                    className="img-fluid shadow rounded w-100"
                    src={blog.image}
                    alt={blog.name}
                  />
                  <p className="fw-bold color-primary py-2 mb-0">{blog.date}</p>
                  <h4>{blog.name}</h4>

                  <p className="desc">{blog.description}</p>
                  <Link to="/Blogs/blog-name" className="read-more">
                    Read More
                  </Link>
                </div>
              ))}
              <div className="col-sm-12 col-md-6 col-lg-7">
                <div className="recent-main-blog"></div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-7"></div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Blogs;
