import React from "react";
import assetsImage from "../assets/assets";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const { pathname } = useLocation();
  return (
    <div>
      <div className="detail-page-header">
        <header className="bg-detail-page" id="header">
          <div className="container d-block d-md-flex align-items-center justify-content-lg-between">
            <Link to="/" className="logo me-auto me-lg-0">
              <img
                loading="lazy"
                decoding="async"
                alt=""
                className="img-fluid"
                src={assetsImage.logo}
              />
            </Link>

            <nav
              className="navbar navbar-expand-md order-last order-lg-0"
              id="navbar"
            >
              <ul className="navbar-nav">
                <li>
                  <Link to="/" className="nav-link scrollto">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="nav-link scrollto">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/training" className="nav-link scrollto">
                    Training
                  </Link>
                </li>
                <li>
                  <Link
                    to="/corporate/talent-solutions"
                    className="nav-link scrollto"
                  >
                    Talent Solutions
                  </Link>
                </li>
                <li>
                  <Link
                    to="/corporate/consulting"
                    className="nav-link scrollto"
                  >
                    Consulting
                  </Link>
                </li>
                <li>
                  <Link to="/Web&App-Development" className="nav-link scrollto">
                    Development
                  </Link>
                </li>
                <li>
                  {/* <Link className="nav-link scrollto" to="/Blogs">
                    Blogs
                  </Link> */}
                </li>
                <li>
                  <Link to="/contact" className="nav-link scrollto">
                    Contact
                  </Link>
                </li>

                <li className="ps-md-4">
                  <a
                    className="theme-btn btn-yellow scrollto text-decoration-none d-block d-sm-block d-md-none"
                    href="tel:+1 (929) 235-5888"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Call Now
                  </a>
                  <a
                    className="theme-btn scrollto text-decoration-none d-none d-sm-none d-md-block btn-yellow"
                    href="https://calendly.com/rohanhossain47/screening-call-with-techsscope?month=2024-04"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Book A Discovery Call
                  </a>
                </li>
              </ul>
            </nav>
            {/* <!-- .navbar --> */}
          </div>
        </header>
        {/* <!-- ======= Breadcrumbs ======= --> */}
        <section className="breadcrumbs">
          <div className="container py-md-5">
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-white pr-3 pb-0">
                <h2>
                  {pathname === "/about" ? (
                    "About"
                  ) : (
                    <>
                      {pathname === "/contact" ? (
                        "Contact"
                      ) : (
                        <>
                          {pathname === "/training" ? (
                            "Training"
                          ) : (
                            <>
                              {pathname === "/corporate/talent-solutions" ? (
                                "Talent Solutions"
                              ) : (
                                <>
                                  {pathname === "/corporate/consulting" ? (
                                    "Consulting"
                                  ) : (
                                    <>
                                      {pathname === "/corporate" ? (
                                        "Corporate"
                                      ) : (
                                        <>
                                          {pathname === "/Web&App-Development"
                                            ? "Web And App Development"
                                            : ""}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </h2>
              </div>
              <ol>
                <li>
                  <Link
                    to="/"
                    className="text-decoration-none"
                    style={{ color: "#7ed957" }}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  {pathname === "/about" ? (
                    "About"
                  ) : (
                    <>
                      {pathname === "/contact" ? (
                        "Contact"
                      ) : (
                        <>
                          {pathname === "/training" ? (
                            "Training"
                          ) : (
                            <>
                              {pathname === "/corporate/talent-solutions" ? (
                                "Talent Solutions"
                              ) : (
                                <>
                                  {pathname === "/corporate/consulting" ? (
                                    "Consulting"
                                  ) : (
                                    <>
                                      {pathname === "/corporate" ? (
                                        "Corporate"
                                      ) : (
                                        <>
                                          {pathname === "/Web&App-Development"
                                            ? "Web And App Development"
                                            : ""}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </li>

                {/* <li>
                  {pathname === "/about" ? (
                    "About"
                  ) : (
                    <>
                      {pathname === "/contact" ? (
                        "Contact"
                      ) : (
                        <>
                          {pathname === "/training" ? (
                            "Training"
                          ) : (
                            <>
                              {pathname === "/corporate/talent-solutions" ? (
                                "Talent Solutions"
                              ) : (
                                <>
                                  {pathname === "/corporate/consulting" ? (
                                    "Consulting"
                                  ) : (
                                    <>
                                      {pathname === "/corporate"
                                        ? "Corporate"
                                        : ""}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </li> */}
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs --> */}
      </div>
    </div>
  );
};

export default Navbar;
