import React, { useEffect, useState } from "react";
import HomeHeader from "../Components/HomeHeader";
import assetsImage from "../assets/assets";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { FaRegSmile, FaBook, FaRegClock, FaAward } from "react-icons/fa";
import Loader from "../Components/Loader";
import Testimonials from "../Components/Testimonials";

const Home = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // 👇️ Page loader
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  var settingsClients = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <HomeHeader />

          {/* About section */}
          <main id="main">
            {/* Featured section */}
            <section className="featured-main">
              <div className="container">
                <div className="section-title text-center">
                  <h2>Featured On</h2>

                  <p className="text-white">
                    Proudly
                    <span className="theme-color"> Showcased </span>On
                  </p>
                </div>
                <div className="row py-4">
                  <div className="col col-sm">
                    <div className="featured-logo text-center">
                      <a href="https://finance.yahoo.com/news/techscope-training-achieves-remarkable-milestone-101700546.html">
                        <img
                          alt=""
                          className="img-fluid pt-3"
                          src={assetsImage.yahoo}
                        ></img>
                      </a>
                    </div>
                  </div>

                  <div className="col col-sm">
                    <div className="featured-logo text-center">
                      <a href="https://markets.businessinsider.com/news/stocks/techscope-training-achieves-remarkable-milestone-with-20-million-in-student-offer-letters-1032838493">
                        <img
                          className="img-fluid pt-2 pt-lg-0"
                          src={assetsImage.BusinessInsider}
                          alt=""
                        ></img>
                      </a>
                    </div>
                  </div>
                  <div className="col col-sm">
                    <div className="featured-logo text-center">
                      <a href="https://apnews.com/press-release/marketersmedia/new-york-d5dc383cb2bdd7d5413ad1fcb925a838">
                        <img src={assetsImage.APNews} alt=""></img>
                      </a>
                    </div>
                  </div>
                  <div className="col col-sm">
                    <div className="featured-logo text-center">
                      <a href="https://ceoweekly.com/techscope-training-achieves-remarkable-milestone-with-20-million-in-student-offer-letters/">
                        <img
                          className="img-fluid pt-2"
                          src={assetsImage.ceo}
                          alt=""
                        ></img>
                      </a>
                    </div>
                  </div>
                  <div className="col col-sm">
                    <div className="featured-logo text-center">
                      <img
                        alt=""
                        className="img-fluid pt-3"
                        src={assetsImage.news}
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* End Featured section */}
            <section className="about" id="about">
              <div
                className="container"
                variants={{
                  hidden: { opacity: 0, y: 75 },
                  visible: { opacity: 1, y: 0 },
                }}
                initial="hidden"
                animate="visible"
              >
                <div className="row py-lg-5">
                  <div className="col-lg-6 order-1 order-lg-2">
                    <div className="ninjaimage text-end position-relative">
                      <img
                        className="img-fluid"
                        src={assetsImage.intro}
                        alt=""
                      />
                      <div id="html-spinner" className="spinner"></div>
                    </div>
                  </div>

                  <div className="col-lg-6 pt-4 py-5 order-2 order-lg-1 content">
                    <div className="section-title">
                      <h2>Who we are</h2>

                      <p className="text-white">
                        <span className="theme-color">Techscope</span> Intro
                      </p>
                    </div>
                    <p className="text-white pe-4 pb-lg-2">
                      At TechScope, we're more than just a company; we're
                      innovators and educators at the forefront of the
                      technological frontier. Our core mission is to empower
                      corporate America through cutting-edge tech training,
                      comprehensive talent solutions, and expert consulting
                      services. Specializing in data solutions, we aim to be the
                      industry's leading provider, bridging the gap between
                      technology and business needs. Our dedication lies in
                      nurturing talent, enhancing skills, and delivering
                      exceptional service, ensuring that every client not only
                      meets but surpasses their technological goals. TechScope
                      is where technology meets expertise, and where your
                      business’s future becomes brighter. Learn more:
                    </p>
                    <div className="row py-3">
                      <div className="col">
                        <div className="text-center services-box card py-1 px-2 text-white">
                          <Link
                            className="text-white card-body text-decoration-none"
                            to="/training"
                          >
                            <img
                              className="px-2 pe-3"
                              src={assetsImage.training}
                              width="50px"
                              alt=""
                            ></img>
                            <i className="fa fa-right"></i>
                            Training
                          </Link>
                        </div>
                      </div>
                      <div className="col">
                        <div className="text-center services-box card py-1 px-2 text-white">
                          <Link
                            className="text-white px-0 card-body text-decoration-none"
                            to="/corporate/talent-solutions"
                          >
                            <img
                              alt=""
                              className="px-2 pe-3"
                              src={assetsImage.businessAnalyst}
                              width="50px"
                            ></img>
                            <i className="fa fa-right"></i>
                            Talent Solutions
                          </Link>
                        </div>
                      </div>
                      <div className="col">
                        <div className="text-center services-box card py-1 px-2 text-white">
                          <Link
                            className="text-white card-body text-decoration-none"
                            to="/corporate/consulting"
                          >
                            <img
                              alt=""
                              className="px-2 pe-3"
                              src={assetsImage.dataEng}
                              width="50px"
                            ></img>
                            <i className="fa fa-right"></i>
                            Consulting
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* End About Section */}

            {/* <!-- ======= Features Section ======= --> */}

            <section className="features" id="features">
              <div className="container pb-lg-5">
                <div className="row">
                  <div className="col-lg-5">
                    <img
                      alt=""
                      className="img-fluid"
                      src={assetsImage.talentSolution}
                    ></img>
                  </div>
                  <div className=" col-lg-7">
                    <div className="pt-5 px-lg-5">
                      <div className="section-title pb-2">
                        <h2>Talent Solutions</h2>

                        <p className="text-white">
                          <span className="theme-color">Bridging Talent </span>{" "}
                          with Opportunity
                        </p>
                      </div>

                      <p className="text-white">
                        At TechScope, we excel in connecting skilled developers
                        and engineers with leading tech organizations. Our
                        approach encompasses thorough recruiting and screening,
                        ensuring a perfect fit for each role.
                      </p>
                      <p className="text-white">
                        For Employers: We're dedicated to providing candidates
                        who are not just qualified, but are the right match for
                        your unique needs, fueling your company's growth.
                      </p>
                      <p className="text-white">
                        For Job Seekers: We prioritize your career goals,
                        placing you in roles where your talents shine and your
                        professional aspirations are met.
                      </p>
                      <p className="text-white">
                        TechScope is where exceptional talent finds its ideal
                        home, and where companies discover their next
                        innovators.
                      </p>
                      <div className="py-4">
                        <Link
                          to="/corporate/talent-solutions"
                          className="theme-btn btn-sm btn-yellow text-decoration-none"
                        >
                          Learn More
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="row py-2">
                      <div className="p-2 mt-lg-0 col-sm-6 col-md-4">
                        <div className="icon-box p-3">
                          <i>
                            <img
                              className="img-fluid"
                              height="50"
                              src={assetsImage.retail}
                              width="50"
                              alt=""
                            />
                          </i>

                          <h4>Retail</h4>
                          <p>
                            Retail is the process of selling goods or services
                            directly to consumers for personal use, typically
                            through physical stores, online platforms, or other
                            distribution channels.
                          </p>
                        </div>
                      </div>

                      <div className="p-2 col-sm-6 col-md-4 ">
                        <div className="icon-box p-3">
                          <i>
                            <img
                              className="img-fluid"
                              height="50"
                              src={assetsImage.banking}
                              width="50"
                              alt=""
                            />
                          </i>

                          <h4>Banking</h4>
                          <p>
                            Banking involves financial institutions providing
                            services such as deposits, loans, and investment
                            management to individuals, businesses, and
                            governments.
                          </p>
                        </div>
                      </div>

                      <div className="p-2 col-sm-6 col-md-4 ">
                        <div className="icon-box p-3">
                          <i>
                            <img
                              className="img-fluid"
                              height="50"
                              src={assetsImage.health}
                              width="50"
                              alt=""
                            />
                          </i>

                          <h4>Healthcare</h4>
                          <p>
                            Healthcare encompasses the prevention, diagnosis,
                            treatment, and management of illnesses and injuries
                            to maintain or improve human health.
                          </p>
                        </div>
                      </div>

                      <div className=" col-sm-6 col-md-4 ">
                        <div className="icon-box p-3">
                          <i>
                            <img
                              className="img-fluid"
                              height="50"
                              src={assetsImage.auto}
                              width="50"
                              alt=""
                            />
                          </i>

                          <h4>Auto</h4>
                          <p>
                            The automotive industry encompasses the design,
                            manufacturing, and sale of vehicles, including cars,
                            trucks, and motorcycles.
                          </p>
                        </div>
                      </div>
                      <div className=" col-sm-6 col-md-4 ">
                        <div className="icon-box p-3">
                          <i>
                            <img
                              className="img-fluid"
                              height="50"
                              src={assetsImage.customerSupport}
                              width="50"
                              alt=""
                            />
                          </i>

                          <h4>Construction</h4>
                          <p>
                            Construction involves the planning, design, and
                            execution of building projects, including
                            residential, commercial, and infrastructure
                            developments.
                          </p>
                        </div>
                      </div>

                      <div className=" col-sm-6 col-md-4 ">
                        <div className="icon-box p-3">
                          <i>
                            <img
                              className="img-fluid"
                              height="50"
                              src={assetsImage.lifeInsurance}
                              width="50"
                              alt=""
                            />
                          </i>

                          <h4>Insurance</h4>
                          <p>
                            Insurance provides financial protection against
                            risks by transferring the burden of potential losses
                            from individuals or businesses to insurance
                            companies.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- End Features Section -->
  
<!-- ======= Services Section ======= --> */}
            {/* <!-- ======= Clients Section ======= --> */}
            <div className="container-fluid">
              <video
                src={assetsImage.homepageVideo}
                width="100%"
                height="auto"
                autoplay="true"
                loop="true"
                controls={false}
                style={{
                  objectFit: "cover",
                }}
              />
            </div>
            <section className="clients" id="clients">
              <div className="container py-lg-5">
                <div className="section-title text-center">
                  <h2>Training</h2>

                  <p className="text-white">
                    Our Students
                    <span className="theme-color"> Have Worked </span>at
                  </p>
                </div>
                <div className="py-5">
                  <Slider {...settingsClients} className="align-items-center">
                    <div className="swiper-slide text-center">
                      <img
                        alt=""
                        className="img-fluid"
                        height="60"
                        src={assetsImage.facebookIco}
                        width="60"
                      />
                    </div>

                    <div className="swiper-slide text-center">
                      <img
                        alt=""
                        className="img-fluid"
                        height="60"
                        src={assetsImage.twitterIco}
                        width="60"
                      />
                    </div>

                    <div className="swiper-slide text-center">
                      <img
                        alt=""
                        className="img-fluid"
                        height="60"
                        src={assetsImage.youtubeIco}
                        width="60"
                      />
                    </div>

                    <div className="swiper-slide text-center">
                      <img
                        alt=""
                        className="img-fluid"
                        height="60"
                        src={assetsImage.windowsIco}
                        width="60"
                      />
                    </div>

                    <div className="swiper-slide text-center">
                      <img
                        alt=""
                        className="img-fluid"
                        height="60"
                        src={assetsImage.healthIco}
                        width="60"
                      />
                    </div>

                    <div className="swiper-slide text-center">
                      <img
                        alt=""
                        className="img-fluid"
                        height="60"
                        src={assetsImage.flagIco}
                        width="60"
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </section>

            {/* <!-- End Clients Section --> */}

            {/* <section className="services accordion" id="services">
    <div className="container py-lg-5" id="accordionServices">
      <div className="section-title">
        <h2>services</h2>

        <p className="text-white">
          Check our <span className="theme-color">services</span>
        </p>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-12 d-flex align-items-stretch">
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <div className="icon">
                  <img
                    className="img-fluid"
                    height="100"
                    src={assetsImage.training}
                    width="100"
                    alt=""
                  />
                  <h4 className="fw-bold">Training</h4>
                </div>
              </div>
              <div className="flip-card-back-training">
                <Link
                  className="text-white text-decoration-none"
                  to="/training"
                >
                  <h4 className="fw-bold">Training</h4>
                  <p>
                    For those with an interest in data engineering, data
                    science, salesforce, data analytics, and cloud
                    computing, we offer training.
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 d-flex align-items-stretch">
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <div className="icon">
                  <img
                    className="img-fluid"
                    height="100"
                    src={assetsImage.businessAnalyst}
                    width="100"
                    alt=""
                  />
                  <h4 className="fw-bold">Staffing</h4>
                </div>
              </div>
              <div className="flip-card-back-staffing">
                <Link
                  className="text-white text-decoration-none"
                  to="/corporate/staffing"
                >
                  <h4 className="fw-bold">Staffing</h4>
                  <p>
                    We present staffing services that are desirable as
                    well as beneficial to both candidates and clients. We
                    assist in closing the gap and make the experience
                    exciting for both parties
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 d-flex align-items-stretch">
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <div className="icon">
                  <img
                    alt=""
                    className="img-fluid"
                    height="100"
                    src={assetsImage.dataEngBg}
                    width="100"
                  />
                  <h4 className="fw-bold">Consulting</h4>
                </div>
              </div>
              <div className="flip-card-back-consult">
                <Link
                  to="/corporate/consulting"
                  className="text-white text-decoration-none"
                >
                  <h4 className="fw-bold">Consulting</h4>
                  <p>
                    We offer consulting services to organizations to help
                    them create and maintain products and solutions that
                    will help them grow.
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}
            {/* <!-- End Services Section -->
<!-- ======= Cta Section ======= --> */}

            {/* <section className="cta" id="cta">
    <div className="container">
      <div className="text-center">
        <h3>
          Corporate <span className="theme-color">Training</span>
        </h3>

        <p className="px-lg-5 mx-lg-5 py-3">
          We train corporate workforces to be able to adapt towards
          gaining new knowledge on skill sets that the business requires.
          Often, numerous team players of an organization are willing to
          undergo training to be able to support their companies for
          further growth through use cases of modern day tools. We offer
          complete guides and live training sessions for your workforce.
          Allow us to help collaborate in making sure you are using the
          best and most fit tool for you.
        </p>
        <Link to="/contact" className="cta-btn text-decoration-none">
          Register Now
        </Link>
      </div>
    </div>
  </section> */}

            <section className="our-company pb-xs-80 pb-100 overflow-hidden">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-sm-6">
                    <div className="our-company__meida">
                      <img
                        src={assetsImage.company1}
                        alt=""
                        className="img-fluid"
                      />

                      <div className="years-experience overflow-hidden bg-red mt-20 mt-sm-10 mt-xs-10 text-center">
                        <div className="number color-white">
                          <i>
                            <FaAward />
                          </i>
                          <span className="counter">10</span>
                          <sup>+</sup>
                        </div>
                        <h4 className="title fw-bold color-white">Awards</h4>
                        <p>
                          All awards associated with TechScope from community
                          forums, academic guidance and associating partners
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6">
                    <div className="our-company__meida border-radius">
                      <img
                        src={assetsImage.company2}
                        alt=""
                        className="img-fluid"
                      />

                      <div className="horizental-bar bg-red"></div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="our-company__content mt-md-50 mt-sm-40 mt-xs-35">
                      <div className="section-title pb-0">
                        <h2>Consulting</h2>

                        <p className="text-white">
                          Unleashing{" "}
                          <span className="theme-color">Innovation</span>,
                          Elevating{" "}
                          <span className="theme-color">Performance</span>
                        </p>
                      </div>

                      <div className="descriiption text-white font-la mb-30 mb-md-25 mb-sm-20 mb-xs-15">
                        <p>
                          Elevate your business with TechScope's diverse IT
                          consulting services. We specialize in comprehensive
                          solutions, from strategic IT management to innovative
                          break-fix and implementation projects. Our expertise
                          in managed IT services ensures seamless, efficient,
                          and secure operations.
                        </p>
                        <p>
                          Leveraging our prowess in managed IT services, we
                          offer end-to-end management of your IT infrastructure,
                          ensuring efficiency, security, and seamless
                          functionality. Our commitment to excellence is
                          reflected in our proactive problem-solving, customized
                          strategy development, and continuous technological
                          advancement.
                        </p>
                        <p>
                          Partner with TechScope for a strategic alliance that
                          brings not just solutions, but a competitive edge to
                          your business. Expect transformative outcomes, guided
                          by our dedication to innovation, agility, and
                          unwavering support for your IT endeavors.
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="service-box">
                            <h4>
                              <i>
                                <FaBook />
                              </i>{" "}
                              85 Projects
                            </h4>
                            <p>
                              Consulting contracts that we took on to support
                              and consult our corporate partners on
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="service-box">
                            <h4>
                              <i>
                                <FaRegClock />
                              </i>{" "}
                              4 Years
                            </h4>
                            <p>
                              Dedicated to specifically our TechScope family
                              clients and customers.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- End Cta Section -->
<!-- ======= Counts Section ======= --> */}

            {/* <!-- End Counts Section -->
<!-- ======= Testimonials Section ======= --> */}
            {/* <Testimonials /> */}
            {/* <!-- End Testimonials Section -->
<!-- ======= Team Section ======= --> */}

            {/* <section className="team" id="team">
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h2>Team</h2>

        <p className="text-white">
          Our talented <span className="theme-color">Team</span>
        </p>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-4 d-flex align-items-stretch">
          <div className="member" data-aos="fade-up" data-aos-delay="100">
            <div className="member-img">
              <img alt="" className="img-fluid" src={assetsImage.rohan} />
              <div className="social"></div>
            </div>

            <div className="member-info">
              <h4>Rohan Hossain</h4>
              <span>Founder and CEO</span>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 d-flex align-items-stretch">
          <div className="member" data-aos="fade-up" data-aos-delay="100">
            <div className="member-img">
              <img
                loading="lazy"
                decoding="async"
                alt=""
                className="img-fluid"
                src={assetsImage.max}
              />
              <div className="social"></div>
            </div>

            <div className="member-info">
              <h4>Max Khan Topu</h4>
              <span>Chief Technical Officer</span>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 d-flex align-items-stretch">
          <div className="member" data-aos="fade-up" data-aos-delay="100">
            <div className="member-img">
              <img alt="" className="img-fluid" src={assetsImage.awais} />
              <div className="social"></div>
            </div>

            <div className="member-info">
              <h4>Awais Nayyar</h4>
              <span>Assistant Director of Academic Affairs</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
          <div className="member" data-aos="fade-up" data-aos-delay="100">
            <div className="member-img">
              <img
                alt=""
                className="img-fluid"
                src={assetsImage.jubaer}
              />
              <div className="social"></div>
            </div>

            <div className="member-info">
              <h4>Jubaer Islam</h4>
              <span>Director of Staffing and Recruiting</span>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
          <div className="member" data-aos="fade-up" data-aos-delay="200">
            <div className="member-img">
              <img
                alt=""
                className="img-fluid"
                src={assetsImage.farman}
              />
              <div className="social"></div>
            </div>

            <div className="member-info">
              <h4>Farman Miah</h4>
              <span>Engineer</span>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
          <div className="member" data-aos="fade-up" data-aos-delay="300">
            <div className="member-img">
              <img
                alt=""
                className="img-fluid"
                src={assetsImage.junaid}
              />
              <div className="social"></div>
            </div>

            <div className="member-info">
              <h4>Junaid Islam</h4>
              <span>Director of Consultancy</span>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
          <div className="member" data-aos="fade-up" data-aos-delay="400">
            <div className="member-img">
              <img alt="" className="img-fluid" src={assetsImage.afran} />
              <div className="social"></div>
            </div>

            <div className="member-info">
              <h4>Afran Hossain</h4>
              <span>Director of Academic Affairs</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}
          </main>
        </div>
      )}
    </div>
  );
};

export default Home;
