import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Testimonials from "../Components/Testimonials";
import assetsImage from "../assets/assets";
import { Link } from "react-router-dom";
import TrainingTestimonials from "../Components/TrainingTestimonials";
import Slider from "react-slick";
const Training = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  var trainingMentors = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div>
      <Navbar />
      <div className="bg-white">
        <div className="top-header-training-bg">
          <div className="container py-5">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-5 col-xl-6 order-xl-2 order-lg-2 order-md-1 d-flex justify-content-center align-items-center">
                <div className="image text-center ">
                  <img
                    className="img-fluid"
                    alt=""
                    src={assetsImage.trainingHeader}
                  ></img>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-7 col-xl-6 order-xl-1 order-lg-1 order-md-2">
                <div
                  className="section-title pr-3"
                  data-aos="fade-right"
                  data-aos-delay="100"
                >
                  <h2>Training</h2>

                  <p className="">
                    OUR
                    <span className="theme-color"> TRAINING</span> PROCEDURE
                  </p>
                </div>
                <p className="pe-lg-0 me-lg-0 pe-xl-5 me-xl-5">
                  Are you interested in pursuing a career in data engineering
                  but not sure where to start? Our data engineering bootcamp is
                  the perfect solution for you. This intensive program covers
                  all the essential skills you need to succeed in the field,
                  including programming languages such as Python and SQL, data
                  modeling and ETL design, and big data technologies like Hadoop
                  and Spark. Our experienced instructors will guide you through
                  hands-on projects and real-world case studies to give you the
                  practical experience you need to succeed. Plus, with a
                  flexible online format, you can learn at your own pace and fit
                  your studies into your busy schedule. Don't let a lack of
                  experience hold you back from your dream career – join our
                  data engineering bootcamp today and take the first step
                  towards a rewarding career in this exciting field. Ohh just
                  for your information.
                </p>
                <p>
                  NO EXPERIENCE OR BACKGROUND NEEDED FOR ANY OF OUR COURSES!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid ">
          <div className="container py-lg-5">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-5 col-xl-6 text-center d-flex align-items-center justify-content-center">
                <img
                  className="img-fluid"
                  alt=""
                  src={assetsImage.speciality}
                ></img>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-7 col-xl-6">
                <div
                  className="section-title pr-3 pt-lg-5"
                  data-aos="fade-right"
                  data-aos-delay="100"
                >
                  <h2>SPECIALTY</h2>

                  <p className="">
                    WHAT WE DO
                    <span className="theme-color"> Different</span>
                  </p>
                </div>
                <p className="pe-lg-0 me-lg-0 pe-xl-5 me-xl-5">
                  At our tech training hub, our seasoned industry experts,
                  boasting a decade of hands-on experience, will guide you. In
                  merely half a year, they'll immerse you in the depths of the
                  field. All sessions are conducted virtually, catering to your
                  convenience. We take attendance and engagement seriously,
                  expecting our students to wholeheartedly commit to the
                  program. Progress is gauged through regular quizzes and
                  monthly exams, and additional assistance is extended to those
                  in need. Classes, usually spanning 2-3 hours, primarily unfold
                  on weekends, with slight variations in schedules. Our courses
                  offer holistic support, paving the way for your placement in a
                  Fortune 500 enterprise. Reach out to us to arrange a
                  personalized discussion and discern if this journey aligns
                  with your aspirations.
                </p>
                <div className="row p2-3">
                  <div className="col-sm-12 col-md-6">
                    <ul className="px-0">
                      <li className="list-group-item">Certification</li>
                      <li className="list-group-item">
                        Linkedin certification
                      </li>
                      <li className="list-group-item">AWS certification</li>
                      <li className="list-group-item">Resume creation</li>
                    </ul>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <ul className="px-0">
                      <li className="list-group-item">Job hunting</li>
                      <li className="list-group-item">Job support</li>
                      <li className="list-group-item">Academy</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="courses-main-bg">
          <div className="container py-3">
            <div className="section-title text-center" id="#courses">
              <h2>courses</h2>

              <p>
                <span className="theme-color">courses</span> we offer
              </p>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-6 col-md-6 col-lg-4 py-2">
                <div className="card rounded-lg shadow p-3">
                  <div className="img text-center">
                    <img
                      className="img-fluid"
                      alt=""
                      src={assetsImage.dataEngineering}
                    ></img>
                  </div>
                  <div className="py-3 text-center">
                    <h5 className="fw-bold">Data Engineering</h5>
                    <p>
                      Data engineering is the process of building and
                      maintaining the infrastructure and systems that are used.
                    </p>
                    <div className="py-2">
                      <Link
                        className="get-started-btn text-dark scrollto px-5"
                        // href="https://forms.gle/eY4canyoj5YAZCod6"
                        to="/contact"
                        // target="_blank"
                        rel="noreferrer"
                      >
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4 py-2">
                <div className="card rounded-lg shadow p-3">
                  <div className="img text-center">
                    <img
                      className="img-fluid"
                      alt=""
                      src={assetsImage.blockchainTech}
                    ></img>
                  </div>
                  <div className="py-3 text-center">
                    <h5 className="fw-bold">Blockchain Technology</h5>
                    <p>
                      The process of designing and building decentralized
                      applications and distributed ledger systems using
                      blockchain technology.
                    </p>
                    <div className="py-2">
                      <Link
                        className="get-started-btn text-dark scrollto px-5"
                        // href="https://forms.gle/eY4canyoj5YAZCod6"
                        to="/contact"
                        // target="_blank"
                        rel="noreferrer"
                      >
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4 py-2">
                <div className="card rounded-lg shadow p-3">
                  <div className="img text-center">
                    <img
                      className="img-fluid"
                      alt=""
                      src={assetsImage.dataScience}
                    ></img>
                  </div>
                  <div className="py-3 text-center">
                    <h5 className="fw-bold">Data Science</h5>
                    <p>
                      Data science is a field that involves using scientific
                      methods, processes, algorithms, and systems to extract
                      knowledge and insights.
                    </p>
                    <div className="py-2">
                      <Link
                        className="get-started-btn text-dark scrollto px-5"
                        // href="https://forms.gle/eY4canyoj5YAZCod6"
                        to="/contact"
                        // target="_blank"
                        rel="noreferrer"
                      >
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4  py-2">
                <div className="card rounded-lg shadow p-3">
                  <div className="img text-center">
                    <img
                      className="img-fluid"
                      alt=""
                      src={assetsImage.saleForce}
                    ></img>
                  </div>
                  <div className="py-3 text-center">
                    <h5 className="fw-bold">Saleforce</h5>
                    <p>
                      Salesforce development is the process of creating custom
                      applications and solutions using the Salesforce platform.
                    </p>
                    <div className="py-2">
                      <Link
                        className="get-started-btn text-dark scrollto px-5"
                        // href="https://forms.gle/eY4canyoj5YAZCod6"
                        to="/contact"
                        // target="_blank"
                        rel="noreferrer"
                      >
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4 py-2">
                <div className="card rounded-lg shadow p-3">
                  <div className="img text-center">
                    <img
                      className="img-fluid"
                      alt=""
                      src={assetsImage.dataAnalytics}
                    ></img>
                  </div>
                  <div className="py-3 text-center">
                    <h5 className="fw-bold">Data Analytics</h5>
                    <p>
                      The goal of data analytics is to help organizations make
                      more informed decisions by turning raw data into
                      actionable insights.
                    </p>
                    <div className="py-2">
                      <Link
                        className="get-started-btn text-dark scrollto px-5"
                        // href="https://forms.gle/eY4canyoj5YAZCod6"
                        to="/contact"
                        // target="_blank"
                        rel="noreferrer"
                      >
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="training-tutors">
          <div className="container py-lg-5">
            <div className="section-title text-center">
              <h2>Instructors</h2>
              <p>
                Some of Our talented{" "}
                <span className="theme-color">instructors</span>
              </p>
            </div>
            <div className="row py-2">
              <div className="col-sm-12 col-md-7 col-lg-8">
                <div className="content pe-lg-5">
                  <h2 className="fw-bolder py-3">Shiwam Porwal</h2>
                  <p className="pe-lg-5">
                    Shivam Porwal graduated from IIT, a prestigious organization
                    from India. Just a note that graduates of IITs are sought
                    after by top employers around the world, and many go on to
                    work at leading tech companies or pursue advanced degrees at
                    prestigious universities. He has taught at many universities
                    and public speeches about his fieldwork. Apart from being a
                    master in Big Data and Artifical Intelligence he also has a
                    great taste for Arts, Music, Poetry and Motivational
                    Speaking
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-5 col-lg-4">
                <div className="img text-center">
                  <img
                    className="img-fluid"
                    alt=""
                    src={assetsImage.shiwamIntructor}
                  ></img>
                </div>
              </div>
            </div>
            <div className="row py-2">
              <div className="col-sm-12 col-md-5 col-lg-4 order-2 order-md-1">
                <div className="img text-center">
                  <img
                    className="img-fluid"
                    alt=""
                    src={assetsImage.navidInstructor}
                  ></img>
                </div>
              </div>
              <div className="col-sm-12 col-md-7 col-lg-8 order-1 order-md-2">
                <div className="content pe-lg-5">
                  <h2 className="fw-bolder py-3">Navid Mudassir</h2>
                  <p className="pe-lg-5">
                    Navid Mudassir came from a zero tech background but was
                    always a curious individual in his field. He is certified in
                    many different aspects of the Salesforce Industry. To name a
                    few he has worked as a Salesforce Administrator, Developer,
                    Consultant as well as a Solutions Architect. He has a heavy
                    business understanding as well as the tech stack to make him
                    a shere force in the current Salesforce market. He is highly
                    enthusiatic and passionate about his work. On the side he is
                    also a family man and loves spending time out in nature.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mentors section */}
        <div className="mentors-main">
          <div className="section-title text-center">
            <h2>Mentors</h2>
            <p>
              Our talented <span className="theme-color">Mentors</span>
            </p>
          </div>
          <div className="container">
            <div className=" py-5">
              <Slider {...trainingMentors}>
                <div className=" swiper-slide">
                  <div className="rounded shadow d-flex row mentor-box">
                    <div className="col-sm-12 col-md-12 col-lg-4 msg-bg px-0">
                      <div className=" px-3 py-4 bg-black h-100 d-flex align-items-center">
                        <div>
                          <p className="text-white fs-4 fw-bolder text-uppercase">
                            Hey Guys ... <br></br>i'm a mentor{" "}
                          </p>
                          <p className="fw-bold text-white">Shiwam Porwal </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-5 col-lg-4 mentor-img text-center">
                      <img
                        className="img-fluid"
                        alt=""
                        src={assetsImage?.mentorImg}
                      ></img>
                    </div>
                    <div className="py-3 col-sm-12 col-md-7 col-lg-4">
                      <p className="fs-4 fw-bolder mb-2">
                        Big Data & AI Expert
                      </p>
                      <p>
                        Shivam Porwal worked for different prestigious
                        organizations all over the world. He has taught at many
                        universities and public speeches about his fieldwork.
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" swiper-slide px-5">
                  <div className="rounded shadow d-flex row mentor-box">
                    <div className="col-sm-12 col-md-12 col-lg-4 msg-bg px-0">
                      <div className=" px-3 py-4 bg-black h-100 d-flex align-items-center">
                        <div>
                          <p className="text-white fs-4 fw-bolder text-uppercase">
                            Hey Guys ... <br></br>i'm a mentor{" "}
                          </p>
                          <p className="fw-bold text-white">Navid Mudassir </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-5 col-lg-4 mentor-img text-center">
                      <img
                        className="img-fluid"
                        alt=""
                        src={assetsImage?.mentorImg}
                      ></img>
                    </div>
                    <div className="py-3 col-sm-12 col-md-7 col-lg-4">
                      <p className="fs-4 fw-bolder mb-2">Sales Force Expert</p>
                      <p>
                        Navid Mudassir came from a zero tech background but was
                        always a curious individual in his field.
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" swiper-slide px-5">
                  <div className="rounded shadow d-flex row mentor-box">
                    <div className="col-sm-12 col-md-12 col-lg-4 msg-bg px-0">
                      <div className=" px-3 py-4 bg-black h-100 d-flex align-items-center">
                        <div>
                          <p className="text-white fs-4 fw-bolder text-uppercase">
                            Hey Guys ... <br></br>i'm a mentor{" "}
                          </p>
                          <p className="fw-bold text-white">Rehan Raqeeb </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-5 col-lg-4 mentor-img text-center">
                      <img
                        className="img-fluid"
                        alt=""
                        src={assetsImage?.mentorImg}
                      ></img>
                    </div>
                    <div className="py-3 col-sm-12 col-md-7 col-lg-4">
                      <p className="fs-4 fw-bolder mb-2">
                        Linux and Git Experts
                      </p>
                      <p>
                        Rehan empowers teams to harness the power of Linux and
                        streamline their development with Git.
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" swiper-slide px-5">
                  <div className="rounded shadow d-flex row mentor-box">
                    <div className="col-sm-12 col-md-12 col-lg-4 msg-bg px-0">
                      <div className=" px-3 py-4 bg-black h-100 d-flex align-items-center">
                        <div>
                          <p className="text-white fs-4 fw-bolder text-uppercase">
                            Hey Guys ... <br></br>i'm a mentor{" "}
                          </p>
                          <p className="fw-bold text-white">Michel Jordan </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-5 col-lg-4 mentor-img text-center">
                      <img
                        className="img-fluid"
                        alt=""
                        src={assetsImage?.mentorImg}
                      ></img>
                    </div>
                    <div className="py-3 col-sm-12 col-md-7 col-lg-4">
                      <p className="fs-4 fw-bolder mb-2">AWS & GCP Expert</p>
                      <p>
                        With deep expertise in AWS and GCP, Micheal designs
                        robust cloud infrastructures for businesses.
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" swiper-slide px-5">
                  <div className="rounded shadow d-flex row mentor-box">
                    <div className="col-sm-12 col-md-12 col-lg-4 msg-bg px-0">
                      <div className=" px-3 py-4 bg-black h-100 d-flex align-items-center">
                        <div>
                          <p className="text-white fs-4 fw-bolder text-uppercase">
                            Hey Guys ... <br></br>i'm a mentor{" "}
                          </p>
                          <p className="fw-bold text-white">Anita Sharma </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-5 col-lg-4 mentor-img text-center">
                      <img
                        className="img-fluid"
                        alt=""
                        src={assetsImage?.mentorImg}
                      ></img>
                    </div>
                    <div className="py-3 col-sm-12 col-md-7 col-lg-4">
                      <p className="fs-4 fw-bolder mb-2">SQL Expert</p>
                      <p>
                        Anita excels in crafting efficient SQL queries and
                        managing complex databases to ensure seamless data
                        operations.
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" swiper-slide px-5">
                  <div className="rounded shadow d-flex row mentor-box">
                    <div className="col-sm-12 col-md-12 col-lg-4 msg-bg px-0">
                      <div className=" px-3 py-4 bg-black h-100 d-flex align-items-center">
                        <div>
                          <p className="text-white fs-4 fw-bolder text-uppercase">
                            Hey Guys ... <br></br>i'm a mentor{" "}
                          </p>
                          <p className="fw-bold text-white">
                            Mohammad Hasnain{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-5 col-lg-4 mentor-img text-center">
                      <img
                        className="img-fluid"
                        alt=""
                        src={assetsImage?.mentorImg}
                      ></img>
                    </div>
                    <div className="py-3 col-sm-12 col-md-7 col-lg-4">
                      <p className="fs-4 fw-bolder mb-2">
                        Visualizations Experts
                      </p>
                      <p>
                        Mohammad specializes in creating impactful
                        visualizations that transform complex data into
                        actionable insights.
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        {/* <div className="intructor-videos-main">
          <div className="py-3 py-lg-5">
            <div className="section-title text-center">
              <h2>Instructors</h2>
              <p>
                SOME OF OUR TALENTED{" "}
                <span className="theme-color">Instructors</span>
              </p>
            </div>
            <div className="py-3 py-lg-5">
              <img
                className="img-fluid"
                alt=""
                src={assetsImage.videoSample}
              ></img>
            </div>
          </div>
        </div> */}
        <div className="container py-lg-5">
          <div className="section-title text-center">
            <h2>Best plan</h2>
            <p>
              that suit your <span className="theme-color">business needs</span>
            </p>
          </div>
          <div className="row text-center justify-content-center ">
            <div className="col-sm-12 col-md-6 col-lg-4 py-1">
              <div className="package-box rounded shadow p-4 plan-box">
                <h3 className="fw-bold pt-3 text-capitalize">Training</h3>
                <h5>( Only Courses )</h5>
                <img
                  className="img-fluid"
                  alt=""
                  src={assetsImage.standardPkg}
                ></img>
                <p className="py-3">
                  Engage in comprehensive courses designed to equip you with
                  in-demand skills and knowledge.
                </p>
                <p className="py-3 border border-top-0 border-end-0 border-start-0">
                  <span className="fs-2 fw-bold">$12</span> / month
                </p>
                <div className="pkg-points py-4">
                  <p className=" text-start">Pay in Full</p>
                  <p className=" text-start">Pay in Installments</p>
                  <p className=" text-start">Deferred Payments</p>
                </div>
                <div className="pt-lg-5">
                  <button className="theme-btn btn-sm btn-yellow" type="button">
                    Get Started
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 py-1">
              <div className="package-box rounded shadow p-4 plan-box">
                <h3 className="fw-bold pt-3 text-capitalize">Placement</h3>
                <h5>( Only Placement Services )</h5>
                <img
                  className="img-fluid"
                  alt=""
                  src={assetsImage.standardPkg}
                ></img>
                <p className="py-3">
                  Receive personalized placement services that match your skills
                  with top job opportunities.
                </p>
                <p className="py-3 border border-top-0 border-end-0 border-start-0">
                  <span className="fs-2 fw-bold">$84</span> / month
                </p>
                <div className="pkg-points py-4">
                  <p className=" text-start">Pay in Full</p>
                  <p className=" text-start">Pay in Installments</p>
                  <p className=" text-start">Deferred Payments</p>
                </div>
                <div className="pt-lg-5">
                  <button className="theme-btn btn-sm btn-yellow" type="button">
                    Get Started
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 py-1">
              <div className="package-box rounded shadow p-4 plan-box">
                <h3 className="fw-bold pt-3 text-capitalize">All Inclusive</h3>
                <h5>( Training and Placement )</h5>
                <img
                  className="img-fluid"
                  alt=""
                  src={assetsImage.enterprisePkg}
                ></img>
                <p className="py-3">
                  Benefit from our all-inclusive package that combines rigorous
                  training with dedicated placement services.
                </p>
                <p className="py-3 border border-top-0 border-end-0 border-start-0">
                  <span className="fs-2 fw-bold">$316</span> / month
                </p>
                <div className="pkg-points py-4">
                  <p className=" text-start">Pay in Full</p>
                  <p className=" text-start">Pay in Installments</p>
                  <p className=" text-start">Deferred Payments</p>
                </div>
                <div className="pt-lg-5">
                  <button className="theme-btn btn-sm btn-yellow" type="button">
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-5 tech-we-progress-section d-none d-md-block text-center">
          <div
            className="section-title pr-3 text-center"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <h2>Technologies We Progress</h2>

            <p className="">
              Our
              <span className="theme-color"> TECHNOLOGIES</span> PROCEDURE
            </p>
          </div>
          <img
            className="img-fluid"
            alt="tech-we-process"
            src={assetsImage.techWeProcess}
          ></img>
        </div>
        <div className="container-fluid px-3 py-5 d-block d-sm-block d-md-none d-lg-none">
          <div
            className="section-title pr-3"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <h2>Technologies We Progress</h2>

            <p className="">
              Our
              <span className="theme-color"> TECHNOLOGIES</span> PROCEDURE
            </p>
          </div>
          <div className="row">
            <div className="col-12 py-2">
              <h4>Data Engineering</h4>
              <p>Building and Maintaining Data Infrastructure</p>
            </div>
            <div className="col-12 py-2">
              <h4>Blockchain Technology</h4>
              <p>Designing Decentralized Applications</p>
            </div>
            <div className="col-12 py-2">
              <h4>Data Science</h4>
              <p>Extracting Knowledge from Data</p>
            </div>
            <div className="col-12 py-2">
              <h4>Salesforce</h4>
              <p>Developing Custom Salesforce Solutions</p>
            </div>
            <div className="col-12 py-2">
              <h4>Data Analytics</h4>
              <p>Transforming Data into Actionable Insights</p>
            </div>
          </div>
        </div>
        <TrainingTestimonials />
      </div>
    </div>
  );
};

export default Training;
