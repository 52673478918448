import React from "react";
import Slider from "react-slick";
const TrainingTestimonials = () => {
  var trainingTestimonial = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
  };
  return (
    <div>
      <section className="testimonials">
        <div className="container" data-aos="zoom-in">
          <div
            className="testimonials-slider swiper"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <Slider {...trainingTestimonial}>
              <div className="swiper-slide">
                <div className="testimonial-item">
                  <h3> Jane Doe</h3>

                  <h4>Data Science Graduate</h4>

                  <p>
                    "The training program exceeded my expectations! The
                    instructors were knowledgeable, and the hands-on projects
                    prepared me for real-world challenges. I secured a job
                    within weeks of completing the course. Highly recommend!"
                  </p>
                </div>
              </div>

              <div>
                <div className="testimonial-item">
                  <h3>Emily Johnson</h3>

                  <h4>Salesforce Development Graduate</h4>

                  <p>
                    "I had a great experience with the Salesforce development
                    program. The trainers were experts in their field and
                    provided valuable insights. The placement team was
                    supportive and guided me through every step of the job
                    application process."
                  </p>
                </div>
              </div>
              {/* <!-- End testimonial item --> */}

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <h3>Michael Brown</h3>

                  <h4>Data Engineering Graduate</h4>

                  <p>
                    "The Data Engineering course was comprehensive and covered
                    everything from basics to advanced topics. The projects were
                    challenging but rewarding. Thanks to the training and
                    placement support, I now work at a top tech company."
                  </p>
                </div>
              </div>
              {/* <!-- End testimonial item --> */}

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <h3>Sarah Davis</h3>

                  <h4>Data Analytics Student</h4>

                  <p>
                    "The Data Analytics course helped me transition into a
                    data-driven role. The training was practical, and the
                    examples were relevant to industry needs. The career
                    services team provided excellent guidance, and I quickly
                    found a job that I love."
                  </p>
                </div>
              </div>

              {/* <!-- End testimonial item --> */}
            </Slider>

            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TrainingTestimonials;
