import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Slider from "react-slick";
import assetsImage from "../assets/assets";

const Staffing = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  var staffingTestimonial = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
  };
  return (
    <div>
      <Navbar />
      {/* <!-- Intro --> */}
      <div className="container py-5 text-white">
        <div className="row">
          <div
            className="col-sm-12 col-md-6 py-3 pe-5"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <div className="section-title">
              <h2>Staffing</h2>
              <p className="text-white">
                How we can <span className="theme-color">help you</span> in
                staffing?
              </p>
            </div>
            <p>
              If you are a business owner or hiring manager, using a recruitment
              agency can save you valuable time and resources in the hiring
              process. Our team of experienced recruiters will handle the
              initial screening and vetting of candidates, allowing you to focus
              on your core responsibilities. We have a diverse pool of qualified
              candidates and use advanced sourcing techniques to find the best
              fit for your company. Our thorough evaluation process ensures that
              the candidates we present to you meet the requirements of the role
              and align with your company's values and culture. Additionally,
              our recruitment services are cost-effective and come with a
              satisfaction guarantee. Using our agency will give you peace of
              mind knowing that you have a team of professionals working to
              bring you top talent.
            </p>
          </div>
          <div
            className="col-sm-12 col-md-6"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <img alt="" src={assetsImage.staffing} className="img-fluid" />
          </div>
        </div>
      </div>
      {/* <!-- End Intro --> */}
      {/* <!-- Staffing --> */}
      <div className="py-5">
        <div className="container text-white py-lg-5">
          <div className="row">
            <div
              className="col-sm-12 col-md-6 pt-2"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <img
                alt=""
                src={assetsImage.teamStaffing}
                className="img-fluid"
              />
            </div>
            <div
              className="col-sm-12 col-md-6 pt-4"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <p>
                Our tech staffing company is dedicated to connecting top-tier
                technology professionals with businesses looking for their
                expertise. What makes us different is that we are more catered
                to our clients requirements and we have heavy technical and
                hands-on background which is what we use in understand the
                importance of finding the right fit for both parties, which is
                why we take the time to carefully evaluate the skills and needs
                of both our clients and candidates. During this process we
                verify candidates before matching them with our clients by using
                our personal hands-on work experience in the field. What we do
                different is that our screening calls are extensive so that no
                amount of time and compensation is wasted for our clients. This
                also helps us in understanding if the tech stack and "fitness"
                of the candidate matches that of the client. Our extensive
                network of highly qualified tech professionals allows us to
                match businesses with the best fit for their project or company.
                Whether you are a business owner in need of talented tech
                professionals or a tech professional looking for your next
                opportunity, our team is here to assist you every step of the
                way.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Staffing --> */}
      {/* Start of process */}
      <section className="process">
        <div className="container">
          <div className="section-title">
            <h2>Process</h2>
            <p className="text-white">
              Our <span className="theme-color">staffing</span> process
            </p>
          </div>
          <div className="timeline-main">
            <div className="timeline-wrap">
              <div className="timeline-card">
                <div className="timeline-card-wrap">
                  <div className="card-head-wrap">
                    <h2 className="timeline-card-head">
                      Understanding Client Requirements:
                    </h2>
                    <h6 className="timeline-card-subhead">#1</h6>
                  </div>
                  <p className="timeline-card-text">
                    1-on-1 call with the hiring manager, director, client, etc
                    to get the requirements and understand it as if it were our
                    recruiters were applying to the same requirement.
                  </p>
                </div>
              </div>
              <div className="timeline-card">
                <div className="timeline-card-wrap">
                  <div className="card-head-wrap">
                    <h2 className="timeline-card-head">
                      Finding potential candidates:
                    </h2>
                    <h6 className="timeline-card-subhead">#2</h6>
                  </div>
                  <p className="timeline-card-text">
                    Resume and skill search on our databases for candidate that
                    have the 'desired' tech stack by the client.
                  </p>
                </div>
              </div>
              <div className="timeline-card">
                <div className="timeline-card-wrap">
                  <div className="card-head-wrap">
                    <h2 className="timeline-card-head">
                      Screening Candidates:
                    </h2>
                    <h6 className="timeline-card-subhead">#3</h6>
                  </div>
                  <p className="timeline-card-text">
                    1-on-1 call with candidates that are interested in the
                    opportunity by out technical recruiters and understanding
                    their experience as if they were the ones hiring for the
                    same requirement.
                  </p>
                </div>
              </div>
              <div className="timeline-card">
                <div className="timeline-card-wrap">
                  <div className="card-head-wrap">
                    <h2 className="timeline-card-head">
                      Submitting Resumes to Client:
                    </h2>
                    <h6 className="timeline-card-subhead">#4</h6>
                  </div>
                  <p className="timeline-card-text">
                    Allowing clients the full privilege to pick and choose from
                    our best fit candidate list.
                  </p>
                </div>
              </div>
              <div className="timeline-card">
                <div className="timeline-card-wrap">
                  <div className="card-head-wrap">
                    <h2 className="timeline-card-head">
                      Preparing for Interviews:
                    </h2>
                    <h6 className="timeline-card-subhead">#5</h6>
                  </div>
                  <p className="timeline-card-text">
                    Setting up and acting as the mediator for the
                    interview/interviews to take place.
                  </p>
                </div>
              </div>
              <div className="timeline-card">
                <div className="timeline-card-wrap">
                  <div className="card-head-wrap">
                    <h2 className="timeline-card-head">Placement:</h2>
                    <h6 className="timeline-card-subhead">#6</h6>
                  </div>
                  <p className="timeline-card-text">
                    Making sure both our candidate and clients are happy with
                    the placement
                  </p>
                </div>
              </div>
              <div className="timeline-card">
                <div className="timeline-card-wrap">
                  <div className="card-head-wrap">
                    <h2 className="timeline-card-head">Guarantee:</h2>
                    <h6 className="timeline-card-subhead">#7</h6>
                  </div>
                  <p className="timeline-card-text">
                    We will make sure we stick around long term and gladly
                    assist our clients and candidates if things are not working
                    out, FREE OF CHARGE.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of process */}

      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Staffing Testimonials</h2>
          <p className="text-white">
            Words by past <span className="theme-color">clients</span>
          </p>
        </div>
      </div>
      <section className="testimonials">
        <div className="container" data-aos="zoom-in">
          <div
            className="testimonials-slider swiper"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <Slider {...staffingTestimonial}>
              <div className="swiper-slide">
                <div className="testimonial-item">
                  <h3>Alena</h3>

                  <h4>Hiring Manager</h4>

                  <p>
                    "Saved the organization a ton of time and resources and
                    vetted the perfect candidate for us"
                  </p>
                </div>
              </div>

              <div>
                <div className="testimonial-item">
                  <h3>Jaden</h3>

                  <h4>VP</h4>

                  <p>
                    "Rohan understood the requirement as if he were applying to
                    the position himself. Made the process very easy while
                    mediating calls with candidates"
                  </p>
                </div>
              </div>
              {/* <!-- End testimonial item --> */}

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <h3>Aman</h3>

                  <h4>VP of Infrastructure</h4>

                  <p>
                    "Jubaer from TechScope sent us short descriptions about each
                    of the talent he sent over. It was very wasy to get a feel
                    for the candidate's experience"
                  </p>
                </div>
              </div>
              {/* <!-- End testimonial item --> */}

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <h3>Nolan</h3>

                  <h4>Hiring Manager</h4>

                  <p>
                    "Prioritized sourcing and staying on top of service for the
                    role to close the position swiftly."
                  </p>
                </div>
              </div>
              {/* <!-- End testimonial item --> */}
            </Slider>

            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Staffing;
